import axios from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../../../../../../apis/apiManaget";
import { ToastContainer, toast } from "react-toastify";
export default function Team() {
  // Define initial state for attendants
  const [teamData, setTeamData] = useState([]);
  const [attendants, setAttendants] = useState([
    {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    },
  ]);
  console.log(attendants);
  useEffect(() => {
    getTeamData();
  }, []);
  const getTeamData = async () => {
    try {
      const token = await localStorage.getItem("access_token");
      const getTeam = await axios.get(API.ATTENDENT.GET_TEAM_DATA, {
        headers: {
          Authorization: `bearer ${token}`,
          "Content-Type": `multipart/form-data; `,
        },
      });
      const setting = getTeam?.data;
      console.log("get data of seting", setting?.attendants);
      setTeamData(setting?.attendants);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Function to handle add new attendant
  const handleAddAttendant = () => {

   teamData.length>0? setTeamData([
      ...teamData,
      { first_name: "", last_name: "", phone: "", email: "" },
    ]):
    setAttendants([
      ...attendants,
      { first_name: "", last_name: "", phone: "", email: "" },
    ]);
  };

  // Function to handle attendant inputs
  const handleAttendantChange = (index, key, value) => {
    const updatedAttendants = [...teamData];
    updatedAttendants[index][key] = value;
    setAttendants(updatedAttendants);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const isFormComplete = (attendant) => {
  return (
      attendant.first_name &&
      attendant.last_name &&
      attendant.phone &&
      validateEmail(attendant.email)
  );
};

useEffect(() => {
  if (attendants.every(isFormComplete)) {
      update();
  }
}, [attendants]);

  // Define initial state for selected information checkboxes
  const [infoSelection, setInfoSelection] = useState({
    customerName: true,
    eventAddress: true,
    customerphone: true,
    customerBalance: true,
  });

  // Function to handle checkbox toggling and update state
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setInfoSelection((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  console.log("===========================>", teamData?.attendants);
  const update = async () => {
    try {
      const token = await localStorage.getItem("access_token");
      const response = await axios.post(
        API.ATTENDENT.ADD_TEAM,
        { users: attendants },
        {
          headers: {
            Authorization: `bearer ${token}`,
            "Content-Type": `multipart/form-data; `,
          },
        }
      );
      console.log("responce data", response);
      if (response.data) {
        getTeamData();
        // setAttendants([
        //   {
        //     first_name: "",
        //     last_name: "",
        //     phone: "",
        //     email: "",
        //   },
        // ])
        toast.success(response.data.message);
      }
      console.log("getScreeningData", response.data); // Log the data received from the backend
    } catch (error) {
      toast.error(error.message);
      console.log("sksjskjdksj", error); // Log any errors that occur during the request
    }
  };

  return (
    <>
      <div className="tab-pane fade" id="content4">
        <div className="row my-3  g-lg-5 g-md-5 g-3">
          {/* ============= Start: Build Up Your Professional Presence Header ============= */}
          <p className=" fw-bolder fs-4 m-0 p-0">
            BUILD UP YOUR PROFESSIONAL PRESENCE{" "}
          </p>
          <p className=" fs-5 p-0 m-0">Only visible to admin</p>
          {/* ============= End: Build Up Your Professional Presence Header ============= */}

          {/* ============= Start: Photo Booth Attendants ============= */}
          <div className="mb-3 my-3 row col-md-12  company-setting-box rounded my-4 bg-white">
            <h3 className="mt-5">Photo Booth Attendants </h3>

            {/* =================== Start: Dynamically Adding From =================== */}
            {teamData.length>0? teamData.map((attendant, index) => {
              return (
                <form className="row g-3 needs-validation" key={index}>
                  <b>Attendant {index + 1}</b>
                  <div className="col-md-6">
                    <label
                      htmlFor={`first_name${index}`}
                      className="form-label"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`first_name${index}`}
                      name={`first_name${index}`}
                      value={attendant.first_name}
                      onChange={(e) =>
                        handleAttendantChange(
                          index,
                          "first_name",
                          e.target.value
                        )
                      }
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor={`last_name${index}`} className="form-label">
                      Last name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`last_name${index}`}
                      name={`last_name${index}`}
                      value={attendant.last_name}
                      onChange={(e) =>
                        handleAttendantChange(
                          index,
                          "last_name",
                          e.target.value
                        )
                      }
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor={`phone${index}`} className="form-label">
                      Phone #
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`phone${index}`}
                      name={`phone${index}`}
                      placeholder="241-555-5555"
                      value={attendant.phone}
                      onChange={(e) =>
                        handleAttendantChange(index, "phone", e.target.value)
                      }
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor={`email${index}`} className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`email${index}`}
                      name={`email${index}`}
                      placeholder="name@companyinfo.com"
                      value={attendant.email}
                      onChange={(e) =>
                        handleAttendantChange(index, "email", e.target.value)
                      }
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>

                  <hr />
                </form>
              );
            }):
            <>
            {attendants?.map((attendant, index) => {
              return (
                <form className="row g-3 needs-validation" key={index}>
                  <b>Attendant {index + 1}</b>
                  <div className="col-md-6">
                    <label
                      htmlFor={`first_name${index}`}
                      className="form-label"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`first_name${index}`}
                      name={`first_name${index}`}
                      value={attendant.first_name}
                      onChange={(e) =>
                        handleAttendantChange(
                          index,
                          "first_name",
                          e.target.value
                        )
                      }
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor={`last_name${index}`} className="form-label">
                      Last name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`last_name${index}`}
                      name={`last_name${index}`}
                      value={attendant.last_name}
                      onChange={(e) =>
                        handleAttendantChange(
                          index,
                          "last_name",
                          e.target.value
                        )
                      }
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor={`phone${index}`} className="form-label">
                      Phone #
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`phone${index}`}
                      name={`phone${index}`}
                      placeholder="241-555-5555"
                      value={attendant.phone}
                      onChange={(e) =>
                        handleAttendantChange(index, "phone", e.target.value)
                      }
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor={`email${index}`} className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`email${index}`}
                      name={`email${index}`}
                      placeholder="name@companyinfo.com"
                      value={attendant.email}
                      onChange={(e) =>
                        handleAttendantChange(index, "email", e.target.value)
                      }
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>

                  <hr />
                </form>
              );
            })}
            </>}
            {/* =================== End: Dynamically Adding From =================== */}

            {/* Add New Attendant Button */}
            <div className="col-6 my-5" onClick={handleAddAttendant}>
              <div className="addAttendant_btn" to="#" role="button">
                <i className="fa-solid fa-plus"></i>
                <span>Add Attendant</span>
              </div>{" "}
            </div>
            {/* <div className="col-6 my-5" onClick={update}>
              <div className="addAttendant_btn" to="#" role="button">
                <i className="fa-solid fa-check"></i>
                <span>SUBMIT</span>
              </div>{" "}
            </div> */}
          </div>

          {/* ============= End: Photo Booth Attendants ============= */}

          {/* ============= Start:Select What Info To Share With Attendants ============= */}
          <div className="mb-3 col-md-12 border bg-white company-setting-box rounded p-lg-3 p-md-2 p-2 my-4">
            <div className="mb-3 col-md-12 my-5">
              <h5 className="bold">
                Select What Info To Share With Attendants
              </h5>
            </div>
            <hr />
            {/* Map over the keys of infoSelection state to render checkboxes */}
            {Object.keys(infoSelection).map((key) => (
              <div className="card border-0 " key={key}>
                <div className="row d-flex d-inline align-items-center my-1">
                  <div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={key}
                        name={key}
                        checked={infoSelection[key]}
                        onChange={handleCheckboxChange} // Call handleCheckboxChange function on change
                      />
                      <div className="form-check-label" htmlFor={key}>
                        <span className="fs-15">
                          {key === "customerName" && "Customer Name"}
                          {key === "eventAddress" && "Event Address"}
                          {key === "customerphone" && "Customer's Phone Number"}
                          {key === "customerBalance" &&
                            "Customer balance due on the day of the event."}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
          {/* ============= Start:Select What Info To Share With Attendants ============= */}
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
