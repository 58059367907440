import { Link } from "react-router-dom";
import "./AllEvents.css";
import NewEventModal from "./NewEventModal/NewEventModal";
import SendToCustomerFormModal from "./SendToCustomerFormModal/SendToCustomerFormModal";
import customerEvents from "./customerEvents";
export default function AllEvents() {
  return (
    <>
      <div className='container-fluid p-lg-5 p-md-5 p-2'>
        <div className='row'>
          <div className='col-lg-10 col-md-10 col-12 mx-auto'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-6'>
                <button
                  type='button'
                  title='New Event'
                  aria-pressed='false'
                  className='but-primary'
                  data-bs-toggle='modal'
                  data-bs-target='#staticBackdrop'>
                  New Event
                </button>
              </div>
              <div className='col-lg-6 col-md-6 col-6 text-end'>
                <p className='fw-bold'>Video Instructions</p>
              </div>
            </div>

            <div className='stage_container d-flex align-items-center justify-content-between'>
              <div className='box active'>
                <p>7</p>
                <h6>
                  ACTIVE
                  <br /> PROJECTS{" "}
                </h6>
                <div className='arrow-right active'></div>
              </div>
              <div className='box'>
                <p>7</p>
                <h6>INQUIRY</h6>
                <div className='arrow-right'></div>
              </div>
              <div className='box'>
                <div className='arrow-left'></div>
                <p>0</p>
                <h6>
                  PROPOSAL <br />
                  SENT
                </h6>
                <div className='arrow-right'></div>
              </div>
              <div className='box'>
                <div className='arrow-left'></div>
                <p>0</p>
                <h6>
                  PROPOSAL <br />
                  SIGNED
                </h6>
                <div className='arrow-right'></div>
              </div>
              <div className='box'>
                <div className='arrow-left'></div>
                <p>0</p>
                <h6>
                  EVENT <br />
                  COMPLETED
                </h6>
                <div className='arrow-right'></div>
              </div>
              <div className='box'>
                <p>0</p>
                <h6>ARCHIVE</h6>
              </div>
            </div>
            <div className='row'>
              <div className='table-responsive-sm d-none d-sm-block'>
                <table className='table table_events mt-5 table-space table-border-less'>
                  <thead className='py-2'>
                    <tr>
                      <th scope='col'>Customer</th>
                      <th scope='col'>PROJECT DATE</th>
                      <th scope='col'>HOURS</th>
                      <th scope='col'>CITY</th>
                      <th scope='col'>PROJECT TYPE</th>
                      <th scope='col'>STAGE</th>
                      <th scope='col'>RECENT ACTIVITY</th>
                      <th scope='col'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {" "}
                    {customerEvents.map((event, i) => (
                      <tr className='px-2 position-relative' key={i}>
                        <td className='event_link'>
                          {" "}
                          <Link to='/customer-events' state={{ event }} key={i}>
                            {event.name}
                          </Link>
                        </td>

                        <td>{event.date}</td>
                        <td>{event.hours ? event.hours : ""}</td>
                        <td>{event.city}</td>
                        <td>{event.projectType}</td>
                        <td>{event.stage}</td>
                        <td>{event.recentActivity}</td>
                        <td>
                          <div className='btn-container first'>
                            <button
                              className='but-primary_tbl'
                              data-bs-toggle='modal'
                              data-bs-target='#exampleModal'>
                              Send Customer
                            </button>
                          </div>

                          <div className='btn-container second'>
                            <div className='btn-group'>
                              <button
                                type='button'
                                className=' dropdown-toggle but-primary_tbl'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'>
                                Options
                              </button>
                              <ul className='dropdown-menu'>
                                <li>
                                  <Link className='dropdown-item' href='#'>
                                    Move Stage
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' href='#'>
                                    Archive
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className='dropdown-item text-danger'
                                    href='#'>
                                    Delete
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* for mobile view */}
              <div className='col-12 d-block d-sm-none mt-4'>
                <div className='customer_box_container'>
                  {customerEvents.map((event, i) => {
                    return (
                      <Link to='/customer-events' state={{ event }} key={i}>
                        <div className='customer_box d-flex justify-content-between align-items-center bg-white shadow-lg'>
                          <div className='customer_info'>
                            <p className='m-0'>Customer Name</p>
                            <span>{event.name}</span>
                          </div>
                          <div>
                            <button
                              type='button'
                              className='dropdown-toggle bg-transparent border-0'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'>
                              <i className='fas fa-ellipsis-v fa-xl'></i>
                            </button>
                            <ul className='dropdown-menu'>
                              <li>
                                <Link className='dropdown-item' href='#'>
                                  Move Stage
                                </Link>
                              </li>
                              <li>
                                <Link className='dropdown-item' href='#'>
                                  Archive
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className='dropdown-item text-danger'
                                  href='#'>
                                  Delete
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Send To Customer Form Modal Here */}
      <SendToCustomerFormModal />
      {/* New Event Modal Here */}
      <NewEventModal />
    </>
  );
}
