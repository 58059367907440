import axios from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../../../../../../apis/apiManaget";
import { ToastContainer, toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "antd";
import "../../../../../../index.css";

export default function EmailSignature() {
  const [signatureVal, setSignatureVal] = useState("");
  const [showModal, setshowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await localStorage.getItem("access_token");
        const response = await axios.get(API.COMPANY.GET_COMPANY_TEMPLATES, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        const setting = response.data.setting;
        setSignatureVal(setting?.signature || "");
      } catch (error) {
        console.error("Error fetching signature data", error);
      }
    };
    fetchData();
  }, []);

  const handleSignatureChange = (content) => {
    setSignatureVal(content);
  };

  const insertCustomText = function () {
    const quill = this.quill;
    const range = quill.getSelection();
    if (range) {
      quill.insertText(range.index, "Custom Text", "bold", true);
    }
  };

  const imageHandler = function () {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const quill = this.quill;
        const range = quill.getSelection();
        quill.insertEmbed(range.index, "image", e.target.result);
      };
      reader.readAsDataURL(file);
    };
  };
  

  const videoHandler = function () {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();
    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const quill = this.quill;
        const range = quill.getSelection();
        quill.insertEmbed(range.index, "video", e.target.result);
      };
      reader.readAsDataURL(file);
    };
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }, { font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const updateSignature = async () => {
    try {
      
      const token = await localStorage.getItem("access_token");
      const response = await axios.post(
        API.COMPANY.COMPANY_TEMPLATES,
        { signature: signatureVal },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data) {
        setshowModal(false);
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error("Error updating signature", error);
    }
  };

  console.log(showModal);
  return (
    <div className="mb-3 col-md-12 bg-white company-setting-box rounded p-lg-3 p-md-2 p-2 my-4">
      <div className="mt-3">
        <p className="fw-bolder fs-4">Email Signature</p>
        <p>
          Your personalized signature will appear on all communication and email
          Templates
        </p>
      </div>
      <div className="mb-3 col-md-12 rounded p-lg-3 p-md-2 p-2 my-4 max-width: 1042px;">
        {/* <ReactQuill 
        theme={'snow'}
        onChange={handleSignatureChange}
        value={signatureVal}
        modules={modules}
        formats={formats}
        placeholder='write from there'
       /> */}
        <p
          className="m-0 fs-6"
          dangerouslySetInnerHTML={{ __html: signatureVal }}
        ></p>
        <div className="mt-4 pt-2">
          <button
            id="submit-btn"
            type="button"
            className="px-5 rounded-pill btn btn-primary btn-lg text-uppercase"
            onClick={() => setshowModal(true)}
          >
            EDIT EMAIL SIGNATURE
          </button>
        </div>
      </div>

      <Modal
        // title="Modal Title"
        visible={showModal}
        onCancel={
   
          updateSignature
        }
        footer={false}
      >
        <ReactQuill
          theme="snow"
          onChange={handleSignatureChange}
          value={signatureVal}
          modules={modules}
          formats={formats}
          placeholder="Write from here..."
          style={{ height: "400px", width: "100%" }}
        />
      </Modal>

      <ToastContainer />
    </div>
  );
}
