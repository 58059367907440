import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import React from "react";

// Import Routing Components For Linking to Web pages
const events = [{ title: "Meeting", start: new Date() }];

function DayCalendar() {
  // The return statement contains the JSX code which is rendered and displayed on the page
  return (
    // ================= *****  TEMPLATES BODY JSX ***** =================
    <>
      <div className='container-fluid p-lg-5 p-md-5 p-2'>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin]} // Include dayGridPlugin for the "Today" button
          initialView='timeGridDay'
          weekends={false}
          events={events}
          eventContent={renderEventContent}
          headerToolbar={{
            start: "prev,next today", // Add 'today' for the Today button
            center: "title",
            end: "", // Month and Day buttons
          }}
          className='bg-white'
        />
      </div>
    </>

    // ================= *****  TEMPLATES BODY JSX ***** =================
  );
}

export { DayCalendar }; /* ========================= We had to remove default. */

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}
