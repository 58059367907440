import React, { useState } from "react";
import CommonDropdownMenu from "../../../Shared/CommonDropdownMenu";

export default function EventInfoForm() {
  // Define state variables for form fields
  const [formData, setFormData] = useState({
    eventName: "",
    eventAddress: "",
    eventDate: {
      date: "",
      startTime: "",
      endTime: "",
    },
    eventType: "",
    leadSource: "",
  });

  // Function to handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle date and time changes
  const handleDateTimeChange = (type, value) => {
    setFormData({
      ...formData,
      eventDate: {
        ...formData.eventDate,
        [type]: value,
      },
    });
  };

  // Function to handle selecting event type
  const handleEventTypeSelect = (value) => {
    setFormData({ ...formData, eventType: value });
  };

  // Function to handle selecting lead source
  const handleLeadSourceSelect = (value) => {
    setFormData({ ...formData, leadSource: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='row mt-3'>
        <div className='col-md-6'>
          <label htmlFor='eventName' className='form-label fs-dark'>
            {" "}
            Event Name
          </label>
          <input
            type='text'
            className='form-control'
            id='eventName'
            name='eventName'
            placeholder='Event Name'
            aria-label='Event Name'
            value={formData.eventName}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className='row mt-3'>
        {/* Event Address */}
        <div className='col-md-6'>
          <label htmlFor='eventAddress' className='form-label fs-dark'>
            {" "}
            Event Address
          </label>
          <input
            type='text'
            className='form-control'
            id='eventAddress'
            name='eventAddress'
            placeholder='Event Address'
            aria-label='Event Address'
            value={formData.eventAddress}
            onChange={handleInputChange}
          />
        </div>
        {/* Event Date */}
        <div className='col-md-6 mt-4 mt-md-0 mb-4'>
          <label htmlFor='eventDate' className='form-label fs-dark'>
            {" "}
            Event Date
          </label>
          <div className='row g-3'>
            <div className='col-sm-4'>
              <input
                className='form-control'
                type='date'
                placeholder='10/10/1983'
                aria-label='Event Date'
                value={formData.eventDate.date}
                onChange={(e) => handleDateTimeChange("date", e.target.value)}
              />
            </div>
            <div className='col-sm-8'>
              <div className='row'>
                <div className='col-sm-6'>
                  <input
                    type='time'
                    className='form-control'
                    placeholder='4:00PM'
                    aria-label='Start Time'
                    value={formData.eventDate.startTime}
                    onChange={(e) =>
                      handleDateTimeChange("startTime", e.target.value)
                    }
                  />
                </div>
                <div className='col-sm-6 mt-3 mt-md-0'>
                  <input
                    type='time'
                    className='form-control'
                    placeholder='2:00PM'
                    aria-label='End Time'
                    value={formData.eventDate.endTime}
                    onChange={(e) =>
                      handleDateTimeChange("endTime", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Event Type */}
        <div className='col-sm-6'>
          <label className='form-label'>Event Type</label>
          <CommonDropdownMenu
            options={["Wedding", "Sweet 16", "Birthday", "Party"]}
            onSelect={handleEventTypeSelect}
          />
        </div>
        {/* Lead Source */}
        <div className='col-sm-6'>
          <label className='form-label'>Lead Source</label>
          <CommonDropdownMenu
            options={["Personal Website", "Google", "Facebook", "Instagram"]}
            onSelect={handleLeadSourceSelect}
          />
        </div>
      </div>
    </form>
  );
}
