import React, { useEffect, useState } from "react";

function CommonDropdownMenu({ options: defaultOptions, onCategorySelect }) {
  // State variables
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [options, setOptions] = useState(defaultOptions);
  const [showInputField, setShowInputField] = useState(false);

  useEffect(() => {
    // Initialize options with default values
    setOptions(defaultOptions);
  }, [defaultOptions]);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle click on existing option
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onCategorySelect) {
      onCategorySelect(option);
    }
  };

  // Handle click on add category button
  const handleAddButtonClick = () => {
    setShowInputField(true);
  };

  // Handle click on create button
  const handleCreateButtonClick = () => {
    if (newCategory.trim() !== "") {
      setOptions([...options, newCategory]);
      setSelectedOption(newCategory); // Select the newly created option
      setNewCategory("");
      setShowInputField(false);
      if (onCategorySelect) {
        onCategorySelect(newCategory);
      }
    }
  };

  return (
    <div className='accordion  position-relative'>
      <div
        className={`accordion-header  w-100  ${isOpen ? "active" : ""}`}
        onClick={toggleDropdown}>
        <span className='selected-option '>{selectedOption}</span>
        <span className='icon overflow-hidden'>
          <i
            className={
              isOpen ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"
            }
          />
        </span>
      </div>
      <div className={`accordion-content ${isOpen ? "active" : ""}`}>
        {/* Render existing options */}
        {options.map((option, index) => (
          <div
            key={index}
            className='option'
            onClick={() => handleOptionClick(option)}>
            {option}
          </div>
        ))}
        {/* Render input field for new category */}
        {showInputField ? (
          <div className='input-with-button d-flex align-items-center'>
            <input
              type='text'
              placeholder=' New Category..'
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
            />
            <button className='add_item_btn' onClick={handleCreateButtonClick}>
              Create
            </button>
          </div>
        ) : (
          // Render add category button
          <button id='button' onClick={handleAddButtonClick}>
            + Add Category
          </button>
        )}
      </div>
    </div>
  );
}

export default CommonDropdownMenu;
