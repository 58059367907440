import React from "react";
import { useLocation } from "react-router-dom";
import "../All-Events/AllEvents.css";
import SendToCustomerFormModal from "../All-Events/SendToCustomerFormModal/SendToCustomerFormModal";
import { EventOverview } from "./EventOverview/EventOverview";
import { EventProducts } from "./EventProducts/EventProducts";
import { Payments } from "./Payments/Payments";

export default function CustomerEvent() {
  let { state } = useLocation();

  const { event } = state || {};
  return (
    <>
      <SendToCustomerFormModal />
      <div className='container-fluid p-lg-5 p-md-5 p-2'>
        <div className='row'>
          <div className='col-lg-10 col-md-10 col-12 mx-auto'>
            <div className='top_title d-flex align-items-start mb-5'>
              <div>
                <h4>
                  {event?.name}{" "}
                  <strong className='text-danger'>(Customer Name)</strong>
                </h4>
                <p className='mb-0 mt-3'>
                  123 Maple Street, Willowbrook, Meadowville, FA 54321{" "}
                  <span className='text-danger'>(Customer Event Address)</span>
                </p>
              </div>

              <button
                className=' but-primary border-bottom-5 mx-auto'
                data-bs-toggle='modal'
                data-bs-target='#exampleModal'>
                Send Customer
              </button>
            </div>
            <nav>
              <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                <button
                  className='nav-link active fs-5'
                  id='nav-home-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-home'
                  type='button'
                  role='tab'
                  aria-controls='nav-home'
                  aria-selected='true'>
                  Event Overview
                </button>
                <button
                  className='nav-link fs-5'
                  id='nav-profile-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-profile'
                  type='button'
                  role='tab'
                  aria-controls='nav-profile'
                  aria-selected='false'>
                  Payment
                </button>
                <button
                  className='nav-link fs-5 border-bottom-5'
                  id='nav-contact-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-contact'
                  type='button'
                  role='tab'
                  aria-controls='nav-contact'
                  aria-selected='false'>
                  Event Products
                </button>
                <button
                  className='nav-link fs-5 border-bottom-5'
                  id='nav-contact-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-contact1'
                  type='button'
                  role='tab'
                  aria-controls='nav-contact'
                  aria-selected='false'>
                  Files
                </button>
              </div>
            </nav>
            <div className='tab-content mt-5' id='nav-tabContent'>
              <div
                className='tab-pane fade  show active'
                id='nav-home'
                role='tabpanel'
                aria-labelledby='nav-home-tab'>
                <EventOverview />
              </div>
              <div
                className='tab-pane fade'
                id='nav-profile'
                role='tabpanel'
                aria-labelledby='nav-profile-tab'>
                <Payments />
              </div>
              <div
                className='tab-pane fade'
                id='nav-contact'
                role='tabpanel'
                aria-labelledby='nav-contact-tab'>
                <EventProducts />
              </div>
              <div
                className='tab-pane fade'
                id='nav-contact1'
                role='tabpanel'
                aria-labelledby='nav-contact-tab'>
                <div className='container-fluid py-5 px-2 bg-white'>
                  <div className='row'>
                    <div className='col-lg-10 col-md-10  col-12 mx-auto my-5'>
                      {event?.recentActivity === "NOT SENT" ? (
                        <div className='files_content'>
                          <div className='image'>
                            <img src='/img/files_image.png' alt='FILES' />
                          </div>

                          <div className='mt-5 text-center '>
                            <h4>Any Files Sent to Customer Will be Here</h4>
                            <p className=''>
                              Keep track of your event files that was used for
                              this event, All files such contracts, invoices,
                              proposals that was sent for This event will be
                              seen here
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h4>Proposal {event?.hours} Hours Event Sent</h4>

                          <div className='mt-4'>
                            <p className='m-0'>Proposal Sent</p>
                            <p className='m-0'>Client has not yet view</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
