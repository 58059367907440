import React from "react";
// Import Routing Components For Linking to Web pages
import { Link, NavLink } from "react-router-dom";
import NewEventModal from "../../Events/All-Events/NewEventModal/NewEventModal";
import "./Sidebar.css";

// const Sidebar is a function that holds the side bar

// sidebarOpen is a boolean that is passed in from the Dashboard-home.js

//  ({ sidebarOpen }) => {...} is a function that uses 'sidebarOpen' to decide whether the sidebar should be open or not.

const Sidebar = ({ sidebarOpen }) => (
  // '${sidebarOpen ? 'open' : ''}` Adds 'open' class when 'sidebarOpen' is true, else adds nothing.
  <>
    <div className={`sidebar pe-0 pt-4 bg-blue ${sidebarOpen ? "open" : ""}`}>
      <nav className='navbar bg-light bg-blue'>
        <a href='index.html' className='navbar-brand mx-5 mb-3'>
          <h3 className='text-white'>LOGO</h3>
        </a>
        <div className='navbar-nav w-100 pt-5'>
          {/* <a
          href="index.html"
          className="nav-item nav-link active text-uppercase"
        >
          <i className="fa fa-home me-3" />
          Dashboard
        </a> */}{" "}
          {/* ================================= Developer commented this code */}
          <NavLink
            to={"/"}
            className='nav-item nav-link text-uppercase'
            activeclassname='active'>
            <i className='fa fa-home me-3' /> Dashboard
          </NavLink>
          {/* We use NavLink so that we can use active route class. */}
          <div className='nav-item dropdown'>
            <Link
              href='#'
              className='nav-link dropdown-toggle text-uppercase'
              data-bs-toggle='dropdown'>
              <i className='fa-solid fa-calendar-days me-3' />
              Calendar
            </Link>
            <div className='dropdown-menu border-0 m-0 p-0' id='bg-dropdown'>
              <Link to='/month-availability' className='dropdown-item nav-link'>
                36 Month Avaibility
              </Link>
              <Link to='/month-calendar' className='dropdown-item nav-link'>
                Month View
              </Link>
              <Link to='/day-calendar' className='dropdown-item nav-link'>
                Day View
              </Link>
            </div>
          </div>
          {/* <a href="templates.html" className="nav-item nav-link text-uppercase">
          <i className="fa-solid fa-briefcase me-3" />
          Templates
        </a> */}
          <NavLink
            to={"/templates"}
            className='nav-item nav-link text-uppercase'
            activeclassname='active'>
            <i className='fa-solid fa-briefcase me-3' /> Templates
          </NavLink>
          <div className='nav-item dropdown'>
            <Link
              href='#'
              className='nav-link dropdown-toggle text-uppercase'
              data-bs-toggle='dropdown'>
              <i className='fa-solid fa-calendar-plus me-3' />
              Events
            </Link>
            <div className='dropdown-menu border-0 m-0 py-0' id='bg-dropdown'>
              <Link
                to={"/events"}
                className='dropdown-item nav-link text-uppercase'>
                View Events
              </Link>

              <div className='dropdown-item nav-link new_event_btn text-center justify-content-start'>
                <button
                  type='button'
                  data-bs-toggle='modal'
                  data-bs-target='#staticBackdrop'>
                  New Event
                </button>
              </div>
            </div>
          </div>
          <Link to='' className='nav-item nav-link text-uppercase'>
            <i className='fa-solid fa-images me-3' />
            Gallery
          </Link>{" "}
          {/* =============================== Developer commend this code */}
          {/* <NavLink to={'/gallery'} className="nav-item nav-link text-uppercase" activeclassname="active"><i className="fa-solid fa-images me-3" /> Gallery</NavLink> */}
          {/* We use NavLink so that we can use active route class. */}
        </div>
      </nav>
    </div>

    {/* Create New Event Modal */}
    <NewEventModal />
  </>
);

export default Sidebar;
