import React, { useEffect, useState } from "react";
import CommonDropdownMenuTwo from "../../../../../Shared/CommonDropdownMenuTwo";
import axios from "axios";
import { API } from "../../../../../../apis/apiManaget";
import { ToastContainer, toast } from "react-toastify";

export default function Preferences() {
  const [data, setdata] = useState([]);
  const [days, setDays] = useState();
  const [dayValue, setDayValue] = useState();
  const [data1, setData] = useState();
  const [active, setActive] = useState([]);

  const handelDays = async (e) => {
    setDays(e.target.value);
    console.log("lksdjf;ljksdf======", days, " lksdjflsdjf", e.target.value);
    setDayValue(e.target.value);
    // console.log("Before while", days);
    //  while(days !== e.target.value){
    //   console.log("in while",days);
    //  }
    //  console.log("After while",days);
    await update(e.target.value);
    // console.log("After Update" , days);
    await getNotification();
    // console.log("selected days",e.target.value);
    // console.log("helooooooo" ,data);
  };
  console.log("lksdjf;ljksdf======", days);

  const getNotification = async () => {
    try {
      const token = await localStorage.getItem("access_token");
      const getPayment = await axios.get(API.COMPANY.GET_NOTIFICATION, {
        headers: {
          Authorization: `bearer ${token}`,
          "Content-Type": `multipart/form-data; `,
        },
      });
      const setting = getPayment?.data?.notifications;
      const allData = setting.map((item) => item.is_active);
      setActive(allData);
      setdata(setting);

      console.log("get data of NOTIFECATION", allData);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getNotification();
  }, []);

  const update = async (option) => {
    console.log(data1);
    try {
      const token = await localStorage.getItem("access_token");
      const Id = await localStorage.getItem("uid");
      const response = await axios.post(
        `${API.COMPANY.NOTIFICATION}/${Id}`,
        {
          value: option,
          is_active: data1?.is_active,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
            "Content-Type": `multipart/form-data; `,
          },
        }
      );
      if (response.data) {
        toast.success(response.data.message);
      }
      // console.log("getScreeningData", response.data); // Log the data received from the backend
    } catch (error) {
      console.log(error); // Log any errors that occur during the request
    }
  };

  const options = ["1 day", "2 days", "3 days", "1 week", "2 weeks"];
  /*================ Start: Notification dummy data================ */

  // console.log("========================>",Id);/

  const toggleHeaderInfo = async (e) => {
    const checked = e.target.checked;
    console.log("checked true", checked);
    const newData = {
      is_active: checked ? 1 : 0, // Set value based on checked state
    };

    // console.log("new data", newData);
    setData(newData);
    await update()

    // Update the formData state after isChecked state is updated
  };
  /*================ End: Notification dummy data================ */

  return (
    <>
      <div className="tab-pane fade" id="content8">
        <div className="companysetting-window-container ">
          <div className="row">
            <div className="mb-3 row col-md-12 bg-white company-setting-box rounded p-lg-3 p-md-2 p-2 my-4">
              {/* ============= Start: Company Notifications Header Section =============*/}
              <div className="mb-3 col-md-8 pl-5 border-left ">
                <p className="fw-bolder fs-4">Company Notifications </p>
                <p className="fs-14">
                  {" "}
                  Select the notifications that you and team members will
                  receive via email{" "}
                </p>
              </div>
              {/* ============= End: Company Notifications Header Section =============*/}
              <hr />

              {/* Loop through notifications */}
              {data.map(
                (notification) => (
                  localStorage.setItem("uid", notification.id),
            
                    // setDayValue(notification.value),
                    // console.log("=====================?",notification),
                    // console.log(notification),
                    <div key={notification.id} className="card border-0">
                      <div className="row d-flex flex-co flex-column-reverse flex-md-row my-1 align-items-center">
                        <div className="col-md-1">
                          <div className="form-check form-switch mt-2 mt-md-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id={`notification-switch-${notification.id}`}
                              // checked={
                              //   notification.is_active == 1 ? true : false
                              // }
                              onChange={(e) => {
                                toggleHeaderInfo(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          {/* <CommonDropdownMenuTwo options={options}  /> */}
                          <select
                            name="value"
                            onChange={(e) => {
                              handelDays(e);
                            }}
                           
                          >
                            {options.map((option, index) => (
                              <option key={index} value={option} >
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-8">
                          <span style={{ fontWeight: "500", fontSize: "14px" }}>
                            {notification.label}
                          </span>
                        </div>
                      </div>
                      <hr />
                    </div>
               
                )
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
