import React from "react";
import { Link } from "react-router-dom";

// const Navbar is a function that holds the navbar
// toggleSidebar is a function that is passed in from the Dashboard-home.js
const CustomerEventHeader = ({ toggleSidebar }) => (
  // JSX Code for the Navbar
  <nav className='navbar navbar-expand bg-light bg-white sticky-top px-lg-5 px-2 py-0'>
    <Link to='/' className='navbar-brand d-flex d-none d-lg-none me-4'>
      <h3 className='m-0'>LOGO</h3>
    </Link>
    {/* ====== Hamburger Button to Toggle The side Bar =========== */}
    <button
      className='sidebar-toggler flex-shrink-0 bg-transparent border-0'
      onClick={toggleSidebar}>
      <i className='fa fa-bars' />
    </button>

    <div className='d-none d-flex align-items-center d-md-flex ms-4'>
      <Link to='/events'>
        <h4>Back</h4>
      </Link>
    </div>
    <div className='navbar-nav align-items-center ms-auto'>
      <div className='money-container nav-item dropdown mx-lg-5 mx-md-2 mx-sm-2'>
        <Link to='' className='nav-link py-3' data-bs-toggle='dropdown'>
          <span className='d-none d-lg-inline-flex me-lg-1'>
            Refer a Friend
          </span>
        </Link>
      </div>
      <div className='money-container nav-item dropdown mx-lg-5 mx-md-2 mx-sm-2'>
        <Link to='' className='nav-link py-3' data-bs-toggle='dropdown'>
          <span className='d-none d-lg-inline-flex me-lg-1'>Support</span>
        </Link>
      </div>
      <div className='nav-item dropdown' id='top-user-nav'>
        <Link to='#' className='nav-link dropdown' data-bs-toggle='dropdown'>
          <span
            className='d-inline-flex p-lg-3 p-md-2 p-2 rounded-circle lead'
            id='user-av'>
            <strong>RE</strong>
          </span>
        </Link>
        <div
          className='dropdown-menu bg-white shadow-sm border-0 py-2 rounded-0 rounded-bottom'
          id='cm-dropdown'>
          <Link to='#' className='dropdown-item py-2'>
            <div className='d-flex align-items-center'>
              <div className='me-2 p-3 rounded-circle text-black' id='user-dp'>
                RE
              </div>
              <span>
                <div>
                  <strong>Ruben Esquivel</strong>
                </div>
                <div className='mt-2'>Company Name</div>
              </span>
            </div>
          </Link>
          <hr className='my-1 py-0' />
          <Link to='/dashboard/company-setting/my-account' className='dropdown-item py-2'>
            <i className='fa-solid fa-user me-2' />
            My Account
          </Link>

          <Link to='/company-setting/my-company' className='dropdown-item py-2'>
            <i className='fa-solid fa-hotel me-2' />
            Company Settings
          </Link>
          <div className='dropdown-item py-2'>
            <div className='d-flex align-items-center justify-content-between mt-5'>
              <Link to='#' className='text-dark'>
                Logout
              </Link>
              <Link to='#' className='text-dark'>
                Help Center
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
);

export default CustomerEventHeader;
