import React, { useEffect, useState } from "react";
// Import Routing Components For Linking to Web pages
import { Link, Navigate } from "react-router-dom";
import "./Contracts.css";

export default function Contracts() {
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(true);
 useEffect(() => {
    const udata = localStorage.getItem('user');
    if (udata === null) {
      setLoggedIn(false);
    } else {
      const odata = JSON.parse(udata);
      setUser(odata.user);
    }
  }, []);
 if (!loggedIn) {
    return <Navigate to="/" />;
  }
  // The return statement contains the JSX code which is rendered and displayed on the page
  return (
    //  ================= *****  TEMPLATES BODY JSX ***** =================
    <>
      <div className='container-fluid  p-lg-5 p-md-5 p-3'>
        <div className='row g-lg-5 g-md-5 g-3'>
          <div className='col-sm-12 col-md-12 col-xl-12'>
            {/* Contracts Templates Description about this page */}
            <div className='mb-4'>
              <h3 className='contract-heading'>Contracts Templates</h3>
              <p className='contract-paragraph'>
                Product templates save time by streamlining the integration of
                individual items into various
                <br /> documents like brochures, invoices, and proposals,
                enabling efficient generation of consistent and
                <br />
                professional-looking files without starting from scratch for
                each new product.
              </p>
            </div>
          </div>
          {/* ========= Create a New Contracts Button ========= */}
          <div id='' className='col-sm-12 col-md-6 col-xl-4 contact-items'>
            <div className='h-100 bg-white rounded p-3 shadow-sm'>
              <Link
                to='/create-contract'
                id=''
                className='h-100 d-flex align-items-center justify-content-center px-2 py-5 p-urls'
                style={{ border: "1px dashed #534dbb" }}>
                <div className='mb-4 text-center create-contract-btn'>
                  <i className='fa-solid fa-plus'></i>
                  <p className='mb-0 fw-light text-uppercase create-contract-text'>
                    CREATE A <br /> NEW CONTRACT
                  </p>
                </div>
              </Link>
            </div>
          </div>
          {/* ========= Basic Photo Booth Contract Button ========= */}
          <div id='' className='col-sm-12 col-md-6 col-xl-4 contact-items'>
            <div className='h-100 bg-white d-flex align-items-center justify-content-center rounded p-4 shadow-sm position-relative'>
              <div className='three-dot-btn'>
                <div className='position-absolute top-0 end-0 me-3 mt-2'>
                  <button className='border-0 bg-transparent ' type='button'>
                    <i className='fa-solid fa-ellipsis fs-3'></i>
                  </button>
                </div>
                <div className='text-center edit-btn'>
                  {/* File With Signature Icon */}

                  <button className='border-0 bg-transparent' type='button'>
                    <i className='fa-solid fa-file-signature'></i>
                  </button>
                  <p className='basic-photo'>
                    BASIC PHOTO <br /> BOOTH CONTRACT
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

    //  ================= *****  TEMPLATES BODY JSX ***** =================
  );
}
