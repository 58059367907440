import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NewEventModal from "../Events/All-Events/NewEventModal/NewEventModal";

const CalendarGrid = ({ initialYear, initialMonth }) => {
  const [currentDate, setCurrentDate] = useState(
    new Date(initialYear, initialMonth)
  );
  const [selectedDates, setSelectedDates] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    setCurrentDate(new Date(initialYear, initialMonth));
  }, [initialYear, initialMonth]);

  const goToPrevious = () => {
    setCurrentDate((prevDate) => {
      const prevMonth = new Date(prevDate);
      prevMonth.setMonth(prevMonth.getMonth() - 1);
      return prevMonth;
    });
  };

  const goToNext = () => {
    setCurrentDate((prevDate) => {
      const nextMonth = new Date(prevDate);
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      return nextMonth;
    });
  };

  const handleDateClick = (day, index) => {
    if (selectedDates.includes(day)) {
      setSelectedDates(selectedDates.filter((date) => date !== day));
      setExpandedIndex(null); // Close the expanded date
    } else {
      setSelectedDates([...selectedDates, day]);
      setExpandedIndex(index); // Expand the clicked date
    }
  };

  const renderMonthHeader = () => {
    return (
      <div className='d-flex align-items-center calender_header'>
        <div>
          <div
            className='btn-group me-2'
            role='group'
            aria-label='Basic example'>
            <button className='btn btn-primary' onClick={goToPrevious}>
              <i className='fa fa-angle-left' aria-hidden='true'></i>
            </button>
            <button className='btn btn-primary' onClick={goToNext}>
              <i className='fa fa-angle-right' aria-hidden='true'></i>
            </button>
          </div>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <h2>
            {currentDate.toLocaleString("default", { month: "long" })}{" "}
            {currentDate.getFullYear()}
          </h2>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <button
            className='btn btn-primary'
            onClick={() => setCurrentDate(new Date())}>
            Today
          </button>
        </div>
      </div>
    );
  };

  const renderMonthView = () => {
    const numDaysInMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const startingDay = firstDayOfMonth.getDay(); // Day of the week (0 - 6)

    const numRows = Math.ceil((startingDay + numDaysInMonth) / 7);

    return (
      <>
        <table className='table table-border mt-3 bg-white text-center month_calender'>
          <thead>
            <tr>
              <th>Sun</th>
              <th>Mon</th>
              <th>Tues</th>
              <th>Wed</th>
              <th>Thu</th>
              <th>Fri</th>
              <th>Sat</th>
            </tr>
          </thead>
          <tbody>
            {[...Array(numRows)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(7)].map((_, colIndex) => {
                  const index = rowIndex * 7 + colIndex;
                  const day = index - startingDay + 1;
                  const isSelected = selectedDates.includes(day);
                  const isCurrentMonthDay =
                    index >= startingDay &&
                    index < startingDay + numDaysInMonth;
                  const isExpanded = expandedIndex === index;
                  return (
                    <td
                      key={colIndex}
                      onClick={() =>
                        isCurrentMonthDay && handleDateClick(day, index)
                      }>
                      {isCurrentMonthDay && (
                        <>
                          <span>{day}</span>
                          <div>
                            {/* <p className='event--text'>event description</p> */}
                          </div>
                          {isSelected && isExpanded && (
                            <>
                              <div className='box-cell-grid'>
                                <h5 style={{ fontSize: "12px" }}>
                                  {day}
                                  <span style={{ float: "right" }}>
                                    <i
                                      className='fa fa-times'
                                      aria-hidden='true'></i>
                                  </span>
                                </h5>
                                <Link
                                  to='/day-calendar'
                                  style={{ fontSize: "12px" }}
                                  onClick={(e) => e.stopPropagation()}>
                                  <i
                                    className='fa fa-calendar'
                                    aria-hidden='true'></i>{" "}
                                  View Day
                                </Link>
                                <Link
                                  style={{ fontSize: "12px" }}
                                  onClick={(e) => e.stopPropagation()}
                                  data-bs-toggle='modal'
                                  data-bs-target='#staticBackdrop'>
                                  <i
                                    className='fa fa-briefcase'
                                    aria-hidden='true'></i>{" "}
                                  Create Event
                                </Link>
                              </div>

                              <div
                                className='custom_popup d-md-none'
                                aria-hidden='true'>
                                <div className='bg-white shadow custom_popup_body'>
                                  <div className='close_btn w-100 d-flex align-items-center justify-content-between'>
                                    <h5 style={{ fontSize: "12px" }}>{day}</h5>
                                    <span style={{ float: "right" }}>
                                      <i
                                        className='fa fa-times'
                                        aria-hidden='true'></i>
                                    </span>
                                  </div>
                                  <div className='w-100 d-flex mt-2 text-start flex-column'>
                                    <Link
                                      to='/day-calendar'
                                      style={{ fontSize: "12px" }}
                                      onClick={(e) => e.stopPropagation()}>
                                      <i
                                        className='fa fa-calendar'
                                        aria-hidden='true'></i>{" "}
                                      View Day
                                    </Link>
                                    <Link
                                      style={{ fontSize: "12px" }}
                                      onClick={(e) => e.stopPropagation()}
                                      data-bs-toggle='modal'
                                      data-bs-target='#staticBackdrop'>
                                      <i
                                        className='fa fa-briefcase'
                                        aria-hidden='true'></i>{" "}
                                      Create Event
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <NewEventModal />
      </>
    );
  };

  return (
    <div>
      {renderMonthHeader()}
      {renderMonthView()}
    </div>
  );
};

export default CalendarGrid;
