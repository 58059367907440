import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API } from "../../../../../../apis/apiManaget";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

export default function PaymentMethods() {
  
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();
  const [clientdetails, setClintde] = useState({
    client_id:"",
    client_secret:""
  });
  const [paymentDetils, setpaymentDetils] = useState([]);
  const [isChecked, setIsChecked] = useState();
  const [userid, setIUserid] = useState();
  const handelPayment = async () => {};
  const toggleHeaderInfo = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked); // Update the checked state immediately
    console.log("checked true", checked);
    const newData = {
      is_active: checked ? 1 : 0, // Set value based on checked state
    };
    console.log("new data", newData);
    setData(newData);
    // Update the formData state after isChecked state is updated
  };
  useEffect(() => {
    const getPaymentDetails = async () => {
      try {
        const token = await localStorage.getItem("access_token");
        const getPayment = await axios.get(API.PAYMENT.GET_PAYMENT_METHOD, {
          headers: {
            Authorization: `bearer ${token}`,
            "Content-Type": `multipart/form-data; `,
          },
        });
        const setting = getPayment?.data?.paymentMethods;
        setpaymentDetils(setting);
        console.log("get data of payment", setting);
      } catch (error) {
        console.log("error", error);
      }
    };
    getPaymentDetails();
  }, []);
  const handleChange=(e)=>{
    setClintde({
      ...clientdetails, 
      [e.target.name] : e.target.value}
    )
  }
  const update = async () => {
    try {
      const token = await localStorage.getItem("access_token");
      console.log("===========================>", userid);
      const response = await axios.post(
        `${API.PAYMENT.UPDATE_PAYMENT}/${userid}`,
        {
          client_secret: clientdetails.client_secret,
          client_id: clientdetails.client_id,
          is_active: data?.is_active,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
            "Content-Type": `multipart/form-data; `,
          },
        }
      );
      if (response.data) {
        toast.success(response.data.message)
      }
      console.log("getScreeningData", response.data); // Log the data received from the backend
    } catch (error) {
      console.log(error); // Log any errors that occur during the request
    }
  };
  return (
    <>
      <div className="tab-pane fade" id="content3">
        {/*=============== Start: Payment Sections Start =============== */}
        <div className="row g-lg-5 g-md-5 g-3 mt-3">
          {/*=============== Start: Payment Section-1 =============== */}
          <div className="mb-3 row col-md-12 gap-4  rounded ">
            <div className="payment-gate-way col-md-5 col-lg-5  ">
              <div className="w-100 text-center image">
                <img src="/img/square.png" alt="square" className="square" />
              </div>
              <div className="btn payment-gate-way-footer d-flex justify-content-between">
                <span className="d-sm-inline-block  text-start ">Connect</span>
                <span className="d-md-inline-block text-end credit">
                  Credit Cards
                </span>
              </div>
            </div>
            <div className="payment-gate-way col-md-5 col-lg-5 ">
              <div className="w-100 text-center image">
                <img src="/img/stripe.png" alt="stripe" className="stripe" />
              </div>
              <div className="payment-gate-way-footer btn d-flex justify-content-between">
                <span className="d-sm-inline-block  text-start ">Connect</span>
                <span className="d-md-inline-block text-end credit">
                  Credit Cards
                </span>
              </div>
            </div>
            <div className="payment-gate-way col-md-5 col-lg-5 ">
              <div className="w-100 text-center image">
                {" "}
                <img src="/img/paypal.png" alt="paypal" className="paypal" />
              </div>

              <div className="payment-gate-way-footer btn d-flex justify-content-between">
                <span className="d-sm-inline-block  text-start ">Connect</span>
                <span className="d-md-inline-block text-end credit">
                  Credit Cards
                </span>
              </div>
            </div>
          </div>
          {/*=============== End: Payment Section-1 =============== */}

          {/*=============== Start: Payment Section-2 =============== */}
          <div className="mb-3 col-md-12 border bg-white product-body rounded p-lg-3 p-md-2 p-2 my-4">
            {isOpen && (
              <div className="row gap-3">
                <label className="    font-family: -apple-system, BlinkMacSystemFont mt-1">
                  client secret
                </label>
                <input
                  className=" col-4 ms-3"
                  type="input"
                  id="flexclintsecret"
                  name="client_secret"
                  checked={true}
                  onChange={(e)=>handleChange(e)}
                  // value={}
                />
                <label className="font-family: -apple-system, BlinkMacSystemFont, mt-1">
                  client_id
                </label>
                <input
                  className=" col-4 ms-3"
                  type="input"
                  id="flexclintid"
                  name="client_id"
                  checked={true}
                  onChange={(e)=>handleChange(e)}
                  // value={}
                />
                <label className="font-family: -apple-system, BlinkMacSystemFont, mt-1">
                  is_Active
                </label>
                <div className="form-check form-switch d-flex align-items-center justify-content-between col-10 ms-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexactiveChecked"
                    name="is_active"
                    checked={isChecked}
                    onChange={(e) => {
                      toggleHeaderInfo(e);
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => setIsOpen(false) / update()}
                  >
                    submit
                  </button>
                </div>
              </div>
            )}
            {!isOpen && (
              <div>
                <div className="mb-3 col-md-6 my-5">
                  <h5 className="bold">Client Payment Methods </h5>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Payment Method </th>
                        <th>Connected To</th>
                        <th>Button Label</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentDetils.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <th scope="row">
                                <i className="fa-solid fa-bars"></i>
                              </th>

                              <td>{item?.payment_method }</td>
                              <td>{item?.connect_to ? item.connect_to :"N/A"}</td>
                              <td>{item?.btn_label ? item?.btn_label : `pay with ${item?.payment_method}`} </td>

                              <td
                                onClick={() => {
                                  setIsOpen(true);
                                  setIUserid(item?.id);
                                }}
                              >
                                <Link
                                  className="btn btn-secondary btn-sm m-1 "
                                  to="#"
                                  role="button"
                                >
                                  edit{" "}
                                </Link>
                                <Link
                                  className="btn btn-primary btn-sm m-1"
                                  to="#"
                                  role="button"
                                >
                                  delete{" "}
                                </Link>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          {/*=============== End: Payment Section-2 =============== */}
        </div>
        {/*=============== End: Payment Sections Start =============== */}
        <ToastContainer/>
      </div>
    </>
  );
}
