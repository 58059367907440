import React from "react";

export default function CreateNewClientForm() {
  return (
    <form>
      <div className='row'>
        <div className='col-md-6'>
          <label>First Name</label>
          <input
            type='text'
            className='form-control'
            placeholder='First name'
          />
        </div>
        <div className='col-md-6'>
          <label>Last Name</label>
          <input type='text' className='form-control' placeholder='Last name' />
        </div>
        <div className='col-md-6 mt-3'>
          <label>Email</label>
          <input
            type='email'
            className='form-control'
            placeholder='Email Address'
          />
        </div>
        <div className='col-md-6 mt-3'>
          <label>Phone Number</label>
          <input
            type='text'
            className='form-control'
            placeholder='Phone Number'
          />
        </div>

        <div className='col-md-12 mt-5 p-4 text-end'>
          <button type='button' className='but-primary' data-bs-dismiss='modal'>
            Add
          </button>
        </div>
      </div>
    </form>
  );
}
