import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom/dist/umd/react-router-dom.development";
import ContactFormHeader from "../components/ContractForm/ContractFormHeader";
import ContactHeader from "../components/Create-Contract/ContactHeader";
import CreateInvoiceHeader from "../components/CreateInvoice/CreateInvoiceHeader";
import CustomerEventHeader from "../components/Events/Customer-Event/CustomerEventHeader/CustomerEventHeader";
import PackageHeader from "../components/Package/PackageHeader";
import ProductHeader from "../components/Products/ProductHeader";
import Header from "../components/Shared/Header/Header";
import Sidebar from "../components/Shared/Sidebar/Sidebar";

export default function DashboardLayout() {
  const { pathname } = useLocation();
  

 
  const noHeader =
    pathname === "/create-contract" ||
    pathname === "/create-invoice" ||
    pathname === "/create-proposals";
  const isAddProduct = pathname === "/add-product";
  const isPackage = pathname === "/create-package";
  const isCreateNewContact = pathname === "/new-contract";
  const isCreateProposals = pathname === "/create-proposals";
  const isCreateInvoice = pathname === "/create-invoice";
  const isCustomerEvent = pathname === "/customer-events";
  // State for managing the sidebar's open/close status
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // State for managing the loader
  const [showLoader, setShowLoader] = useState(true);

  // Function that toggles the state controlling the sidebar's open/close status
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Funciton for showing the loader for 1 second only when the page loads
  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 1000); // Adjust the delay time as needed

    return () => clearTimeout(loaderTimeout);
  }, []);
  return (
    <div
      className={`position-relative bg-white d-flex p-0 ${
        sidebarOpen ? "sidebar-open" : ""
      }`}>
      {/* ============== loader ============== */}
      {showLoader && (
        <div className='bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center'>
          <div
            className='spinner-border text-primary'
            style={{ width: "3rem", height: "3rem" }}
            role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}

      {/* === Sidebar Component. The 'sidebarOpen' prop controls whether the Sidebar is open or closed. ===== */}
      <Sidebar sidebarOpen={sidebarOpen} />

      {/* ========= Main Content Area ========= */}
      <div className={`content ${sidebarOpen ? "open" : ""}`}>
        {/* Navbar Component with a 'toggleSidebar' prop, which is a function to toggle the sidebar's open/closed state */}
        {isCustomerEvent ? (
          <CustomerEventHeader toggleSidebar={toggleSidebar} />
        ) : isPackage ? (
          <PackageHeader toggleSidebar={toggleSidebar} />
        ) : isAddProduct ? (
          <ProductHeader toggleSidebar={toggleSidebar} />
        ) : isCreateProposals ? (
          <ContactHeader toggleSidebar={toggleSidebar} />
        ) : noHeader ? (
          <ContactHeader toggleSidebar={toggleSidebar} />
        ) : isCreateInvoice ? ( // Render CreateInvoiceHeader if the pathname is /create-invoice
          <CreateInvoiceHeader toggleSidebar={toggleSidebar} />
        ) : isCreateNewContact ? (
          <ContactFormHeader toggleSidebar={toggleSidebar} />
        ) : (
          <Header toggleSidebar={toggleSidebar} />
        )}

        {/* =========== Main Content Area for JSX =========== */}
        <div
          className={`container-fluid overflow-x-hidden ${
            !noHeader && "p-lg-5 p-md-5"
          }  ${noHeader && "bg-white"}`}>
          <div className='row g-lg-5 g-md-5 g-3 align-items-center justify-content-center'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
