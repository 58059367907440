// const BASE_URL = 'http://192.168.18.26:3000'
// const BASE_URL = 'http://192.168.10.14:3000'
// const BASE_URL = 'http://192.168.0.101:3000'
// const BASE_URL = 'http://192.168.18.58:3000'
// const BASE_URL = 'http://192.168.18.44:3030'
// const BASE_URL = 'http://127.0.0.1:8000';
const BASE_URL = "https://kingbooth-api.devop360.com/api";
export const API = {
  USER: {
    SIGNUP: `${BASE_URL}/auth/register`,
    LOGIN: `${BASE_URL}/auth/login`,
  },
  COMPANY: {
    COMPANY_TEMPLATES: `${BASE_URL}/user/setting/template`,
    GET_COMPANY_TEMPLATES: `${BASE_URL}/user/setting/template`,
    NOTIFICATION: `${BASE_URL}/user/setting/notification`,
    GET_NOTIFICATION: `${BASE_URL}/user/setting/notification`,
    EDIT_COMPANY_INFO: `${BASE_URL}/company`,
  },
  ATTENDENT: {
    ADD_TEAM: `${BASE_URL}/attendant`,
    GET_TEAM_DATA: `${BASE_URL}/attendant`,
  },
  PAYMENT: {
    UPDATE_PAYMENT: `${BASE_URL}/payment-method`,
    GET_PAYMENT_METHOD: `${BASE_URL}/payment-method`,
  },
};
