import { useEffect, useState } from "react";
import BrandElement from "./BrandEelement";
import EmailSignature from "./EmailSignature";
import { API } from "../../../../../../apis/apiManaget";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from "react-router-dom";

export default function CompanyBrand() {



  const [editCompany, setEditCompany] = useState(false);
  const [companyname, setCompanyName] = useState("");
  const [formData, setFormData] = useState({
    companyEmail: "",
    phoneNumber: "",
    companyWebsite: "",
  });

  const data = localStorage.getItem("user");
  const [userdata, setUserData] = useState(null);

  useEffect(() => {
    if (data) {
      const parsedData = JSON.parse(data);
      setUserData(parsedData);
      setCompanyName(parsedData?.user?.company_name || "");
      setFormData({
        companyEmail: parsedData?.user?.company_email || "",
        phoneNumber: parsedData?.user?.phone_number || "",
        companyWebsite: parsedData?.user?.company_website || "",
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "company_name") {
      setCompanyName(value);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleEditCompany = ()=>{
    setEditCompany(false);
    update()
  }
  const update = async () => {
    try {
      
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        API.COMPANY.EDIT_COMPANY_INFO,
        {
          company_name: companyname,
          company_website: formData.companyWebsite,
          company_phone: formData.phoneNumber,
          company_email: formData.companyEmail,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        console.log("data====================///////////", response.data);
        toast(response.data.message);

        // Update local storage with new company data
        const updatedUserdata = {
          ...userdata,
          user: {
            ...userdata.user,
              company_name: response.data.company.company_name,
              company_email: response.data.company.company_email,
              company_phone: response.data.company.company_phone,
              company_website: response.data.company.company_website,
          },
        };

        localStorage.setItem("user", JSON.stringify(updatedUserdata));

        // Update state
        setUserData(updatedUserdata);
        setCompanyName(response.data.company.company_name);
        setFormData({
          companyEmail: response.data.company.company_email,
          phoneNumber: response.data.company.company_phone,
          companyWebsite: response.data.company.company_website,
        });
      }
      console.log("data that get from api for company", formData);
    } catch (error) {
      toast(error);
      console.error("Error updating company info:", error);
    }
  };
  return (
    <>
      <div className="tab-pane fade show active company_brand" id="content7">
        <div className="justify-content-center my-3">
          <p className="title fw-bolder fs30 my-4">
            BUILD UP YOUR PROFESSIONAL PRESENCE{" "}
          </p>
          <p className="fs16 m-0">Only visible to admin</p>

          <div className="companysetting-window-container">
            <div className="mt-2">
              <div className="row">
                <div className="mb-3 row col-md-12 bg-white company-setting-box p-lg-3 p-md-2 p-2 my-4 company_brand_form">
                  <div className="mb-3 col-md-4 pl-5 company_name">
                    {editCompany && (
                      <input
                        type="text"
                        name="company_name"
                        onChange={handleChange}
                        autoFocus
                        value={companyname}
                        onBlur={ handleEditCompany}
                      />
                    )}
                    {!editCompany && <h5>{companyname}</h5>}
                    <p>
                      {userdata?.user?.first_name} {userdata?.user?.last_name}
                    </p>
                    {!editCompany && (
                      <div className="icon" onClick={() => setEditCompany(true)}>
                        <i className="fas fa-pen"></i>
                      </div>
                    )}
                  </div>

                  <div className="mb-3 col-md-7">
                    <div className="mb-3">
                      <label htmlFor="companyEmail" className="form-label">
                        Company Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="name@companyemail.com"
                        id="companyEmail"
                        name="companyEmail"
                        value={formData.companyEmail}
                        onChange={handleChange}
                        required
                        onBlur={update}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="phoneNumber" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="214-555-5555"
                        value={formData?.phoneNumber}
                        onChange={handleChange}
                        required
                        onBlur={update}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="companyWebsite" className="form-label">
                        Company Website
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyWebsite"
                        name="companyWebsite"
                        placeholder="www.website-name.com"
                        value={formData.companyWebsite}
                        onChange={handleChange}
                        required
                        onBlur={update}
                      />
                    </div>
                  </div>
                </div>

                <BrandElement />
                <EmailSignature />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
