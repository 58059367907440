import React, { useEffect, useState } from "react";
// Import Routing Components For Linking to Web pages
import { Link, Navigate } from "react-router-dom";

// COMPONENTS

function Package() {
 
  // The return statement contains the JSX code which is rendered and displayed on the page
  return (
    //  ================= *****  TEMPLATES BODY JSX ***** =================
    <div className='container-fluid p-lg-5 p-md-5 p-3'>
      <div className='row g-lg-5 g-md-5 g-3'>
        <div className='col-sm-12 col-md-12 col-xl-12'>
          {/* Product Templates Description about this page */}
          <div className='mb-4'>
            <h3 className='mb-1'>Packages Templates</h3>
            <p>
              Packages consist of a collection of services or items that are
              typically bundled together, accompanied by their <br /> respective
              prices. Package templates serve as fundamental components,
              enabling you to seamlessly integrate <br /> your services into
              various file formats such as brochures, invoices, and proposals.
            </p>
          </div>
        </div>
        {/* ========= Create a New Product Button ========= */}
        <div className='col-sm-12 col-md-6 col-xl-4 product-items'>
          <div className='h-100 bg-white rounded p-3 shadow-sm'>
            <Link
              to='/create-package'
              id=''
              className='h-100 d-flex align-items-center justify-content-center px-2 py-5 p-urls'
              style={{ border: "1px dashed #534dbb" }}>
              <div className='mb-4 text-center'>
                <i
                  className='fa-solid fa-plus'
                  style={{ fontSize: "70px" }}></i>
                <h6 className='mb-0 fw-bold text-uppercase'>
                  CREATE A NEW PACKAGE
                </h6>
              </div>
            </Link>
          </div>
        </div>
        {/* ========= Product Template ========= */}
        <div className='col-sm-12 col-md-6 col-xl-4 product-items'>
          <div className='h-100 bg-white rounded p-4 shadow-sm position-relative'>
            <div className='d-flex justify-content-between'>
              <div id='p-meta'>
                <h6>
                  2 HR Firefly Photo Booth Package <br /> (Example)
                </h6>
                <p style={{ fontSize: 12, marginTop: "20px" }}>
                  Created at May 10, 2023
                </p>
              </div>
              <div id='p-dot' style={{ fontSize: 6 }}>
                <i
                  className='fa-solid fa-ellipsis fs-3'
                  style={{ color: "#94a3b8" }}></i>
              </div>
            </div>
            <div
              id='product-cart'
              style={{ fontSize: "15px", color: "#94a3b8" }}>
              <i className='fa fa-shopping-cart pe-2' aria-hidden='true' />
              Product
            </div>
          </div>
        </div>

        <div className='col-sm-12 col-md-6 col-xl-4 product-items'>
          <div className='h-100 bg-white rounded p-4 shadow-sm position-relative'>
            <div className='d-flex justify-content-between'>
              <div id='p-meta'>
                <h6>
                  3 HR Firefly Photo Booth Package <br /> (Example)
                </h6>
                <p style={{ fontSize: 12, marginTop: "20px" }}>
                  Created at May 10, 2023
                </p>
              </div>
              <div id='p-dot' style={{ fontSize: 6 }}>
                <i
                  className='fa-solid fa-ellipsis fs-3'
                  style={{ color: "#94a3b8" }}></i>
              </div>
            </div>
            <div
              id='product-cart'
              style={{ fontSize: "15px", color: "#94a3b8" }}>
              <i className='fa fa-shopping-cart pe-2' aria-hidden='true' />
              Product
            </div>
          </div>
        </div>
        {/* ========= Product Template - END ========= */}
      </div>
    </div>

    // ================= *****  TEMPLATES BODY JSX ***** =================
  );
}

export { Package }; /* ========================= We had to remove default. */
