import React, { createContext, useContext, useState } from "react";

const EditableContentContext = createContext();

export const EditableContentProvider = ({ children }) => {
  /* This is for the add product page */
  const [editableProductContent, setEditableProductContent] =
    useState("Untitled Item");

  /* This is for the create package page */
  const [editablePackageContent, setEditablePackageContent] =
    useState("Untitled Package");

  /* This is for the add product page */
  const handleProductContentChange = (newContent) => {
    setEditableProductContent(newContent);
  };

  /* This is for the create package page */
  const handlePackageContentChange = (newContent) => {
    setEditablePackageContent(newContent);
  };

  return (
    <EditableContentContext.Provider
      value={{
        editableProductContent,
        handleProductContentChange,
        editablePackageContent,
        handlePackageContentChange,
      }}>
      {children}
    </EditableContentContext.Provider>
  );
};

export const useEditableContent = () => {
  return useContext(EditableContentContext);
};
