import React, { useState } from "react";

export default function CustomerInfoForm() {
  // Define state variables for form fields
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  // Function to handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <div className='row mt-3'>
      <div className='col-md-6 mb-4'>
        <label htmlFor='firstName' className='form-label fs-dark'>
          First Name{" "}
        </label>
        <input
          type='text'
          className='form-control'
          id='firstName'
          name='firstName'
          value={formData.firstName}
          onChange={handleInputChange}
          placeholder='First Name'
          aria-label='First name'
        />
      </div>
      <div className='col-md-6 mt-4 mt-md-0'>
        <label label='lastName' className='form-label'>
          Last Name
        </label>
        <input
          name='lastName'
          id='lastName'
          type='text'
          value={formData.lastName}
          onChange={handleInputChange}
          className='form-control'
          placeholder='Last Name'
          aria-label='Last Name'
        />
      </div>

      <div className='col-md-6'>
        <label htmlFor='email' className='form-label fs-dark'>
          {" "}
          Email Address
        </label>
        <input
          type='email'
          className='form-control'
          id='email'
          name='email'
          value={formData.email}
          onChange={handleInputChange}
          placeholder='Email Address'
          aria-label='Email Address'
        />
      </div>
      <div className='col-md-6'>
        <label htmlFor='phone' className='form-label fs-dark'>
          {" "}
          Phone Number
        </label>
        <input
          type='number'
          className='form-control'
          id='phone'
          name='phone'
          value={formData.phone}
          onChange={handleInputChange}
          placeholder='Enter Phone'
          aria-label='Enter Phone'
        />
      </div>
    </div>
  );
}
