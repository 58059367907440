import React, { useState } from "react";

export default function MyAccountTab() {
  const [formData, setFormData] = useState({
    fullName: "",
    jobTitle: "",
    email: "",
    phoneNumber: "",
    personalAddress: "",
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    // You can perform form submission logic here
    console.log("Form submitted:", formData);
  };

  return (
    <>
      <div
        className='tab-pane margin-top-80 fade show active'
        id='MyAccount_Info'>
        <div className='container g-lg-5 g-md-5 g-3 justify-content-center'>
          <div className='company-setting-box rounded p-lg-3 p-md-2 p-2 my-4 bg-white'>
            <div className='row'>
              <div className='mb-3 col-md-6'>
                <label htmlFor='fullName' className='form-label'>
                  Full Name
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='fullName'
                  name='fullName'
                  placeholder='Enter your full name'
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='mb-3 col-md-6 '>
                <label htmlFor='jobTitle' className='form-label'>
                  Job Title
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='jobTitle'
                  name='jobTitle'
                  placeholder='Enter your job title'
                  value={formData.jobTitle}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className='mb-3 col-md-6 '>
                <label htmlFor='emailAddress' className='form-label'>
                  Email Address{" "}
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='emailAddress'
                  name='email'
                  placeholder='name@companyemail.com'
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className='mb-3 col-md-6 '>
                <label htmlFor='phoneNumber' className='form-label'>
                  Phone Number{" "}
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='phoneNumber'
                  name='phoneNumber'
                  placeholder='Enter your phone number'
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className='mb-3 col-md-6 '>
                <label htmlFor='personalAddress' className='form-label'>
                  Personal Address{" "}
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='personalAddress'
                  name='personalAddress'
                  placeholder='Enter your personal address'
                  value={formData.personalAddress}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className='company-setting-box rounded p-lg-3 p-md-2 p-2 my-4 bg-white'>
            <h5>Change Password</h5>
            <div className='row my-3'>
              <div className='mb-3 col-md-6'>
                <label htmlFor='currentPassword' className='form-label'>
                  Current Password
                </label>
                <input
                  type='password'
                  className='form-control'
                  id='currentPassword'
                  name='currentPassword'
                  placeholder='Enter your current password'
                  value={formData.currentPassword}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className='mb-3 col-md-6'>
                <label htmlFor='newPassword' className='form-label'>
                  New Password
                </label>
                <input
                  type='password'
                  className='form-control'
                  id='newPassword'
                  name='newPassword'
                  placeholder='Enter your new password'
                  value={formData.newPassword}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className='mb-3 col-md-6'>
                <label htmlFor='confirmNewPassword' className='form-label'>
                  Confirm New Password
                </label>
                <input
                  type='password'
                  className='form-control'
                  id='confirmNewPassword'
                  name='confirmNewPassword'
                  placeholder='Confirm your new password'
                  value={formData.confirmNewPassword}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className='mb-3 col-md-6 justify-content-center '>
                <button
                  id='submit-btn'
                  type='button'
                  className='px-5 mt-4 rounded-pill btn btn-primary btn-lg text-uppercase'
                  onClick={handleSubmit}>
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
