// Importing necessary modules
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { EditableContentProvider } from "./Context/EditableContentContext.js";
import { router } from "./Route/Route.js";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import 'react-toastify/dist/ReactToastify.css';
// Connecting to the root element in the index.html file
const root = ReactDOM.createRoot(document.getElementById("root"));

// Pushing the Dashboard component to the root element which is the "ID Root" div in the index.html file
root.render(
  <React.StrictMode>
    <EditableContentProvider>
      {/* ====================================== Developer comment this code. */}
      <RouterProvider router={router} />
      {/* ========================== Created a Route.js and import it here. */}
    </EditableContentProvider>
  </React.StrictMode>
);

// Calling the function to measure and report web performance metrics
reportWebVitals();
