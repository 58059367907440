import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { API } from "../../apis/apiManaget";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
    const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIN, setLoggedIN] = useState(() => {
    const udata = localStorage.getItem("user");
    return udata !== null;
  });
const navigate = useNavigate()
  const onChangeUserEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const userLogin = async (email, password) => {
    console.log("Email:", email);
    console.log("Password:", password);

    // Check if email or password is missing
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("===============================>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

    if (!email || !password) {
      toast.error("Please fill in all fields");
    } else {
      const userData = {
        email: email,
        password: password,
      };
      console.log("UserData:", userData);

      try {
        // Make a POST request to login endpoint
        const response = await axios.post(API.USER.LOGIN, {
          email,
          password,
        });

        if (response.status === 200) {
            setLoggedIN(true);
            localStorage.setItem('user', JSON.stringify(response.data));
        }
    
        // Save access token to local storage
        await localStorage.setItem(
          "access_token",
          response?.data?.access_token
        );

        // If login is successful, show success message and navigate to dashboard after 2 seconds
        toast.success("Login Successfully!");
        setTimeout(() => {
            // navigate("/dashboard");
        }, 2000);
      } catch (error) {
        // If login fails, show error message
        toast.error("Login Failed. Please try again.");
      }
    }

    setEmail("");
    setPassword("");
  };

  if (loggedIN) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="App">
      {/* <Outernavbar /> */}
      <div className="auth-wrapper">
        <div className="auth-inner">
          <form onSubmit={onSubmit}>
            <h3>Sign In</h3>
            <p className="forgot-password text-right">
              Don't have account <a href="/sign-up">join us?</a>
            </p>
            <div className="mb-3">
              <label>Email address</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                onChange={onChangeUserEmail}
                name="email"
                value={email}
              />
            </div>
            <div className="mb-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                onChange={onChangePassword}
                name="password"
                value={password}
              />
            </div>
            <div className="mb-3">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Remember me
                </label>
              </div>
            </div>
            <div className="d-grid">
              <button type="submit" className="btn btn-primary">
                Login
              </button>
            </div>
          </form>
          <GoogleOAuthProvider clientId="386932037035-k8v833noqjk7m4***********.apps.googleusercontent.com">
            <React.StrictMode>{/* <App /> */}</React.StrictMode>
          </GoogleOAuthProvider>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default Login;
