import React, { useState } from "react";
import CommonDropdownMenuTwo from "../../../Shared/CommonDropdownMenuTwo";
import CustomerInfoForm from "./CustomerInfoForm";
import EventInfoForm from "./EventInfoForm";

function EventOverview() {
  const optionsEventAttendant = ["Don", "Kevin", "Sammy", "Joe"];
  const optionsReminder = [
    "1 Hour Before",
    "1 Day Before",
    "3 Days Before",
    "7 Days Before",
  ];
  const [attendantColumns, setAttendantColumns] = useState([
    {
      showAddButton: false,
      items: [],
    },
  ]);
  const [reminderColumns, setReminderColumns] = useState([
    {
      showAddButton: false,
      items: [],
    },
  ]);

  // Function to handle option selection for Attendant columns
  const handleAttendantOptionSelect = (index) => {
    const updatedColumns = [...attendantColumns];
    updatedColumns[index].showAddButton = true;
    setAttendantColumns(updatedColumns);
  };

  // Function to handle click on add button for Attendant columns
  const handleAttendantAddButtonClick = (index) => {
    const updatedColumns = [...attendantColumns];
    updatedColumns.push({
      showAddButton: false,
      items: [],
    });
    setAttendantColumns(updatedColumns);
  };

  // Function to handle option selection for Reminder columns
  const handleReminderOptionSelect = (index) => {
    const updatedColumns = [...reminderColumns];
    updatedColumns[index].showAddButton = true;
    setReminderColumns(updatedColumns);
  };

  // Function to handle click on add button for Reminder columns
  const handleReminderAddButtonClick = (index) => {
    const updatedColumns = [...reminderColumns];
    updatedColumns.push({
      showAddButton: false,
      items: [],
    });
    setReminderColumns(updatedColumns);
  };

  return (
    <>
      <div className='container-fluid py-5 px-2 bg-white'>
        <div className='row'>
          <div className='col-lg-10 col-md-10 col-12 mx-auto'>
            <h4>Customer Info</h4>
            <CustomerInfoForm />
          </div>
          <div className='col-lg-10 col-md-10 col-12 mx-auto'>
            <h4 className='mt-4'>Event Info</h4>
            <EventInfoForm />
            <div className='mt-4'>
              <h4>Event Attendant</h4>
              <p className='mt-2'>
                Select how many attendants will be at the event and select how
                many reminders you want <br /> them to receive about the event.
                Each reminder will be applied to all attendants added.
              </p>
              <div className='row mt-2 d-flex justify-content-between'>
                {/* Attendant columns */}
                <div className='col-sm-6'>
                  {attendantColumns.map((column, index) => (
                    <div className='row' key={index}>
                      <div className='col-sm-12'>
                        <label className='form-label'>
                          Installer/Attendant
                        </label>
                        <CommonDropdownMenuTwo
                          options={optionsEventAttendant}
                          handleOptionSelect={() =>
                            handleAttendantOptionSelect(index)
                          }
                          showAddButton={column.showAddButton}
                          handleAddButtonClick={() =>
                            handleAttendantAddButtonClick(index)
                          }
                        />
                        {/* Render addBtnEvent div if showAddButton is true */}
                        {column.showAddButton && (
                          <div className='addBtnEvent'>
                            <button
                              onClick={() =>
                                handleAttendantAddButtonClick(index)
                              }>
                              <i className='fa-solid fa-plus'></i>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                {/* Reminder columns */}
                <div className='col-sm-6'>
                  {reminderColumns.map((column, index) => (
                    <div className='row' key={index}>
                      <div className='col-sm-12'>
                        <label className='form-label'>Reminder</label>
                        <CommonDropdownMenuTwo
                          options={optionsReminder}
                          handleOptionSelect={() =>
                            handleReminderOptionSelect(index)
                          }
                          showAddButton={column.showAddButton}
                          handleAddButtonClick={() =>
                            handleReminderAddButtonClick(index)
                          }
                        />
                        {/* Render addBtnEvent div if showAddButton is true */}
                        {column.showAddButton && (
                          <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex reminder_field'>
                              <div className='form-check me-4'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='flexCheckChecked'
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked me-1'>
                                  Email
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='flexCheckChecked'
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked'>
                                  Text
                                </label>
                              </div>
                            </div>
                            <div className='addBtnEvent'>
                              <button
                                onClick={() =>
                                  handleReminderAddButtonClick(index)
                                }>
                                <i className='fa-solid fa-plus'></i>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='mb-3'>
                <label
                  htmlFor='exampleFormControlTextarea1'
                  className='form-label'>
                  Event Notes
                </label>
                <textarea
                  className='form-control'
                  id='exampleFormControlTextarea1'
                  rows='4'></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { EventOverview };
