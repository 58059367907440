import React, { useState } from "react";

export default function Form() {
  const [showDynamicContent1, setShowDynamicContent1] = useState(false);
  const [showDynamicContent2, setShowDynamicContent2] = useState(false);
  const [showDynamicContent3, setShowDynamicContent3] = useState(false);
  const [showDynamicContent4, setShowDynamicContent4] = useState(false);
  const [showDynamicContent5, setShowDynamicContent5] = useState(false);
  const [showDynamicContent6, setShowDynamicContent6] = useState(false);
  const [showDynamicContent7, setShowDynamicContent7] = useState(false);
  const handleToggleContent1 = (e) => {
    e.stopPropagation();
    setShowDynamicContent1((prevState) => !prevState);
    setShowDynamicContent2(false); // Close other dynamic content if open
  };

  const handleToggleContent2 = (e) => {
    e.stopPropagation();
    setShowDynamicContent2((prevState) => !prevState);
    setShowDynamicContent1(false); // Close other dynamic content if open
  };
  const handleToggleContent3 = (e) => {
    e.stopPropagation();
    setShowDynamicContent3((prevState) => !prevState);
    setShowDynamicContent1(false); // Close other dynamic content if open
    setShowDynamicContent2(false); // Close other dynamic content if open
  };
  const handleToggleContent4 = (e) => {
    e.stopPropagation();
    setShowDynamicContent4((prevState) => !prevState);
    setShowDynamicContent1(false); // Close other dynamic content if open
    setShowDynamicContent2(false); // Close other dynamic content if open
    setShowDynamicContent3(false); // Close other dynamic content if open
  };
  const handleToggleContent5 = (e) => {
    e.stopPropagation();
    setShowDynamicContent5((prevState) => !prevState);
    setShowDynamicContent1(false); // Close other dynamic content if open
    setShowDynamicContent2(false); // Close other dynamic content if open
    setShowDynamicContent3(false); // Close other dynamic content if open
    setShowDynamicContent4(false); // Close other dynamic content if open
  };
  const handleToggleContent6 = (e) => {
    e.stopPropagation();
    setShowDynamicContent6((prevState) => !prevState);
    setShowDynamicContent1(false); // Close other dynamic content if open
    setShowDynamicContent2(false); // Close other dynamic content if open
    setShowDynamicContent3(false); // Close other dynamic content if open
    setShowDynamicContent4(false); // Close other dynamic content if open
    setShowDynamicContent5(false); // Close other dynamic content if open
  };
  const handleToggleContent7 = (e) => {
    e.stopPropagation();
    setShowDynamicContent7((prevState) => !prevState);
    setShowDynamicContent1(false); // Close other dynamic content if open
    setShowDynamicContent2(false); // Close other dynamic content if open
    setShowDynamicContent3(false); // Close other dynamic content if open
    setShowDynamicContent4(false); // Close other dynamic content if open
    setShowDynamicContent5(false); // Close other dynamic content if open
    setShowDynamicContent6(false); // Close other dynamic content if open
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit");
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        {/* Full Name Field */}
        <div className='col-12'>
          <div className='mb-3 form_input'>
            <label htmlFor='name' className='form-label mt-0'>
              Full Name
            </label>
            <input
              type='text'
              className='form-control'
              id='name'
              name='name'
              placeholder='Your name here'
            />
            {/* side_ellipse */}
            <div className='side_ellipse'>
              <div className='text-dark doubleEllipsis' href='#'>
                <i className='fas fa-ellipsis-v fa-xl  me-1'></i>
                <i className='fas fa-ellipsis-v fa-xl '></i>
              </div>
            </div>
            {/* add button */}
            <div className='plus_btn' onClick={handleToggleContent1}>
              <i className='fa fa-plus'></i>
            </div>

            {/* dynamic content part*/}
            <div
              className={`dynamic-content-container ${
                showDynamicContent1 ? "visible" : ""
              }`}>
              <div className='content-header py-4'>
                <div className='position-relative'>
                  <h4>Select the type of block you’d like to add:</h4>
                  <button
                    className='removeBoxBtn'
                    onClick={() => setShowDynamicContent1(false)}>
                    <i className='fa fa-times' aria-hidden='true'></i>
                  </button>
                </div>

                {/* Tab button area */}
                <ul
                  className='nav nav-pills mb-3 justify-content-center mt-3'
                  id='pills-tab'
                  role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='pills-home-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-home'
                      type='button'
                      role='tab'
                      aria-controls='pills-home'
                      aria-selected='true'>
                      CONTENT
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='pills-profile-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-profile'
                      type='button'
                      role='tab'
                      aria-controls='pills-profile'
                      aria-selected='false'>
                      QUESTIONS
                    </button>
                  </li>
                </ul>
              </div>

              {/* Tab content area */}
              <div className='tab-content mt-4 pb-5 ' id='pills-tabContent'>
                <div
                  className='tab-pane fade show active'
                  id='pills-home'
                  role='tabpanel'
                  aria-labelledby='pills-home-tab'>
                  {/* Content Tab */}
                  <div className='row gy-4'>
                    {/* Full width box */}
                    <div className='col-6 col-md-6 col-lg-4'>
                      {" "}
                      <div className='content-box '>
                        <div className='item_box bg-white shadow-lg'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-12 skeleton-one'></div>
                            <div className='col-12 skeleton-two'></div>
                            <div className='col-12 skeleton-three'></div>
                            <div className='col-12 skeleton-four'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Full Width Text</h4>
                      </div>
                    </div>
                    {/*2 column text box */}
                    <div className='col-6 col-md-6 col-lg-4'>
                      {" "}
                      <div className='content-box column-text'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Text</h4>
                      </div>
                    </div>

                    {/*2 column Image box */}
                    <div className='col-6 col-md-6 col-lg-4'>
                      {" "}
                      <div className='content-box column-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Image</h4>
                      </div>
                    </div>
                    {/*2 cover Image box */}
                    <div className='col-6 col-md-6 col-lg-4'>
                      {" "}
                      <div className='content-box cover-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img src='/img/cover.png' alt='skeleton' />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Cover Image</h4>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Question Tab Content */}
                <div
                  className='tab-pane fade'
                  id='pills-profile'
                  role='tabpanel'
                  aria-labelledby='pills-profile-tab'>
                  <div className='row gy-4'>
                    {/* Two fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box twoFields'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className=' skeleton-one'></div>
                            </div>
                            <div className='skeleton-box '>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Two fields</h4>
                      </div>
                    </div>
                    {/* Short text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box shortText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Short Text</h4>
                      </div>
                    </div>
                    {/* Long text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box longText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Long Text</h4>
                      </div>
                    </div>
                    {/* Dropdown fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/dropdown-e-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Dropdown</h4>
                      </div>
                    </div>
                    {/* single-choice-grey  fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/single-choice-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Single Choice</h4>
                      </div>
                    </div>
                    {/* multi-choice-blue */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-12 d-flex align-items-center'>
                              <img
                                src='/img/multi-choice-blue.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Multi choice blue</h4>
                      </div>
                    </div>

                    {/*Date Picker box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-text'>
                        <div className='item_box flex-wrap bg-white shadow-lg d-flex'>
                          <div
                            className='box_items '
                            style={{ columnGap: "5px" }}>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Date Picker</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Email Field */}
        <div className='col-12'>
          <div className='mb-3 form_input'>
            <label htmlFor='name' className='form-label mt-0'>
              Email
            </label>
            <input
              type='text'
              className='form-control'
              id='name'
              name='name'
              placeholder='E.g. myemail@email.com'
            />
            {/* side_ellipse */}
            <div className='side_ellipse'>
              <div className='text-dark doubleEllipsis' href='#'>
                <i className='fas fa-ellipsis-v fa-xl  me-1'></i>
                <i className='fas fa-ellipsis-v fa-xl '></i>
              </div>
            </div>
            {/* add button */}
            <div className='plus_btn' onClick={handleToggleContent2}>
              <i className='fa fa-plus'></i>
            </div>

            {/* dynamic content part*/}
            <div
              className={`dynamic-content-container ${
                showDynamicContent2 ? "visible" : ""
              }`}>
              <div className='content-header py-4'>
                <div className='position-relative'>
                  <h4>Select the type of block you’d like to add:</h4>
                  <button
                    className='removeBoxBtn'
                    onClick={() => setShowDynamicContent2(false)}>
                    <i className='fa fa-times' aria-hidden='true'></i>
                  </button>
                </div>

                {/* Tab button area */}
                <ul
                  className='nav nav-pills mb-3 justify-content-center mt-3'
                  id='pills-tab2'
                  role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='pills-home-tab2'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-home2'
                      type='button'
                      role='tab'
                      aria-controls='pills-home'
                      aria-selected='true'>
                      CONTENT
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='pills-profile-tab2'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-profile2'
                      type='button'
                      role='tab'
                      aria-controls='pills-profile'
                      aria-selected='false'>
                      QUESTIONS
                    </button>
                  </li>
                </ul>
              </div>

              {/* Tab content area */}
              <div className='tab-content mt-4 pb-5 ' id='pills-tabContent'>
                <div
                  className='tab-pane fade show active'
                  id='pills-home2'
                  role='tabpanel'
                  aria-labelledby='pills-home-tab1'>
                  {/* Content Tab */}
                  <div className='row gy-4'>
                    {/* Full width box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box '>
                        <div className='item_box bg-white shadow-lg'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-12 skeleton-one'></div>
                            <div className='col-12 skeleton-two'></div>
                            <div className='col-12 skeleton-three'></div>
                            <div className='col-12 skeleton-four'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Full Width Text</h4>
                      </div>
                    </div>
                    {/*2 column text box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-text'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Text</h4>
                      </div>
                    </div>

                    {/*2 column Image box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Image</h4>
                      </div>
                    </div>
                    {/*2 cover Image box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box cover-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img src='/img/cover.png' alt='skeleton' />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Cover Image</h4>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Question Tab Content */}
                <div
                  className='tab-pane fade'
                  id='pills-profile2'
                  role='tabpanel'
                  aria-labelledby='pills-profile-tab'>
                  <div className='row gy-4'>
                    {/* Two fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box twoFields'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className=' skeleton-one'></div>
                            </div>
                            <div className='skeleton-box '>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Two fields</h4>
                      </div>
                    </div>
                    {/* Short text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box shortText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Short Text</h4>
                      </div>
                    </div>
                    {/* Long text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box longText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Long Text</h4>
                      </div>
                    </div>
                    {/* Dropdown fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/dropdown-e-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Dropdown</h4>
                      </div>
                    </div>
                    {/* single-choice-grey  fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/single-choice-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Single Choice</h4>
                      </div>
                    </div>
                    {/* multi-choice-blue */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-12 d-flex align-items-center'>
                              <img
                                src='/img/multi-choice-blue.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Multi choice blue</h4>
                      </div>
                    </div>
                    {/*Date Picker box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-text'>
                        <div className='item_box flex-wrap bg-white shadow-lg d-flex'>
                          <div
                            className='box_items '
                            style={{ columnGap: "5px" }}>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Date Picker</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Phone Number Field */}
        <div className='col-12'>
          <div className='mb-3 form_input'>
            <label htmlFor='name' className='form-label mt-0'>
              Phone Number
            </label>
            <input
              type='text'
              className='form-control'
              id='name'
              name='name'
              placeholder='E.g. 214-555-1234'
            />
            {/* side_ellipse */}
            <div className='side_ellipse'>
              <div className='text-dark doubleEllipsis' href='#'>
                <i className='fas fa-ellipsis-v fa-xl  me-1'></i>
                <i className='fas fa-ellipsis-v fa-xl '></i>
              </div>
            </div>
            {/* add button */}
            <div className='plus_btn' onClick={handleToggleContent3}>
              <i className='fa fa-plus'></i>
            </div>

            {/* dynamic content part*/}
            <div
              className={`dynamic-content-container ${
                showDynamicContent3 ? "visible" : ""
              }`}>
              <div className='content-header py-4'>
                <div className='position-relative'>
                  <h4>Select the type of block you’d like to add:</h4>
                  <button
                    className='removeBoxBtn'
                    onClick={() => setShowDynamicContent3(false)}>
                    <i className='fa fa-times' aria-hidden='true'></i>
                  </button>
                </div>

                {/* Tab button area */}
                <ul
                  className='nav nav-pills mb-3 justify-content-center mt-3'
                  id='pills-tab3'
                  role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='pills-home-tab3'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-home3'
                      type='button'
                      role='tab'
                      aria-controls='pills-home'
                      aria-selected='true'>
                      CONTENT
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='pills-profile-tab3'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-profile3'
                      type='button'
                      role='tab'
                      aria-controls='pills-profile'
                      aria-selected='false'>
                      QUESTIONS
                    </button>
                  </li>
                </ul>
              </div>

              {/* Tab content area */}
              <div className='tab-content mt-4 pb-5 ' id='pills-tabContent1'>
                <div
                  className='tab-pane fade show active'
                  id='pills-home3'
                  role='tabpanel'
                  aria-labelledby='pills-home-tab'>
                  {/* Content Tab */}
                  <div className='row gy-4'>
                    {/* Full width box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box '>
                        <div className='item_box bg-white shadow-lg'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-12 skeleton-one'></div>
                            <div className='col-12 skeleton-two'></div>
                            <div className='col-12 skeleton-three'></div>
                            <div className='col-12 skeleton-four'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Full Width Text</h4>
                      </div>
                    </div>
                    {/*2 column text box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-text'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Text</h4>
                      </div>
                    </div>

                    {/*2 column Image box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Image</h4>
                      </div>
                    </div>
                    {/*2 cover Image box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box cover-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img src='/img/cover.png' alt='skeleton' />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Cover Image</h4>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Question Tab Content */}
                <div
                  className='tab-pane fade'
                  id='pills-profile3'
                  role='tabpanel'
                  aria-labelledby='pills-profile-tab'>
                  <div className='row gy-4'>
                    {/* Two fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box twoFields'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className=' skeleton-one'></div>
                            </div>
                            <div className='skeleton-box '>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Two fields</h4>
                      </div>
                    </div>
                    {/* Short text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box shortText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Short Text</h4>
                      </div>
                    </div>
                    {/* Long text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box longText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Long Text</h4>
                      </div>
                    </div>
                    {/* Dropdown fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/dropdown-e-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Dropdown</h4>
                      </div>
                    </div>
                    {/* single-choice-grey  fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/single-choice-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Single Choice</h4>
                      </div>
                    </div>
                    {/* multi-choice-blue */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-12 d-flex align-items-center'>
                              <img
                                src='/img/multi-choice-blue.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Multi choice blue</h4>
                      </div>
                    </div>
                    {/*Date Picker box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-text'>
                        <div className='item_box flex-wrap bg-white shadow-lg d-flex'>
                          <div
                            className='box_items '
                            style={{ columnGap: "5px" }}>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Date Picker</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Project Date Field */}
        <div className='col-12'>
          <div className='mb-3 form_input'>
            <label htmlFor='name' className='form-label mt-0'>
              Project Date
            </label>
            <input
              type='date'
              className='form-control'
              id='name'
              name='name'
              placeholder='E.g. 214-555-1234'
            />
            {/* side_ellipse */}
            <div className='side_ellipse'>
              <div className='text-dark doubleEllipsis' href='#'>
                <i className='fas fa-ellipsis-v fa-xl  me-1'></i>
                <i className='fas fa-ellipsis-v fa-xl '></i>
              </div>
            </div>
            {/* add button */}
            <div className='plus_btn' onClick={handleToggleContent4}>
              <i className='fa fa-plus'></i>
            </div>

            {/* dynamic content part*/}
            <div
              className={`dynamic-content-container ${
                showDynamicContent4 ? "visible" : ""
              }`}>
              <div className='content-header py-4'>
                <div className='position-relative'>
                  <h4>Select the type of block you’d like to add:</h4>
                  <button
                    className='removeBoxBtn'
                    onClick={() => setShowDynamicContent4(false)}>
                    <i className='fa fa-times' aria-hidden='true'></i>
                  </button>
                </div>

                {/* Tab button area */}
                <ul
                  className='nav nav-pills mb-3 justify-content-center mt-3'
                  id='pills-tab4'
                  role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='pills-home-tab4'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-home4'
                      type='button'
                      role='tab'
                      aria-controls='pills-home'
                      aria-selected='true'>
                      CONTENT
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='pills-profile-tab4'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-profile4'
                      type='button'
                      role='tab'
                      aria-controls='pills-profile'
                      aria-selected='false'>
                      QUESTIONS
                    </button>
                  </li>
                </ul>
              </div>

              {/* Tab content area */}
              <div className='tab-content mt-4 pb-5 ' id='pills-tabContent1'>
                <div
                  className='tab-pane fade show active'
                  id='pills-home4'
                  role='tabpanel'
                  aria-labelledby='pills-home-tab'>
                  {/* Content Tab */}
                  <div className='row gy-4'>
                    {/* Full width box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box '>
                        <div className='item_box bg-white shadow-lg'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-12 skeleton-one'></div>
                            <div className='col-12 skeleton-two'></div>
                            <div className='col-12 skeleton-three'></div>
                            <div className='col-12 skeleton-four'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Full Width Text</h4>
                      </div>
                    </div>
                    {/*2 column text box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-text'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Text</h4>
                      </div>
                    </div>

                    {/*2 column Image box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Image</h4>
                      </div>
                    </div>
                    {/*2 cover Image box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box cover-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img src='/img/cover.png' alt='skeleton' />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Cover Image</h4>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Question Tab Content */}
                <div
                  className='tab-pane fade'
                  id='pills-profile4'
                  role='tabpanel'
                  aria-labelledby='pills-profile-tab'>
                  <div className='row gy-4'>
                    {/* Two fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box twoFields'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className=' skeleton-one'></div>
                            </div>
                            <div className='skeleton-box '>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Two fields</h4>
                      </div>
                    </div>
                    {/* Short text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box shortText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Short Text</h4>
                      </div>
                    </div>
                    {/* Long text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box longText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Long Text</h4>
                      </div>
                    </div>
                    {/* Dropdown fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/dropdown-e-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Dropdown</h4>
                      </div>
                    </div>
                    {/* single-choice-grey  fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/single-choice-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Single Choice</h4>
                      </div>
                    </div>
                    {/* multi-choice-blue */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-12 d-flex align-items-center'>
                              <img
                                src='/img/multi-choice-blue.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Multi choice blue</h4>
                      </div>
                    </div>

                    {/*Date Picker box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-text'>
                        <div className='item_box flex-wrap bg-white shadow-lg d-flex'>
                          <div
                            className='box_items '
                            style={{ columnGap: "5px" }}>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Date Picker</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Address Field */}
        <div className='col-12'>
          <div className='mb-3 form_input'>
            <label htmlFor='name' className='form-label mt-0'>
              Address
            </label>
            <input
              type='text'
              className='form-control'
              id='name'
              name='name'
              placeholder='E.g. 842 Thomas Hines, Fort Worth'
            />
            {/* side_ellipse */}
            <div className='side_ellipse'>
              <div className='text-dark doubleEllipsis' href='#'>
                <i className='fas fa-ellipsis-v fa-xl  me-1'></i>
                <i className='fas fa-ellipsis-v fa-xl '></i>
              </div>
            </div>
            {/* add button */}
            <div className='plus_btn' onClick={handleToggleContent5}>
              <i className='fa fa-plus'></i>
            </div>

            {/* dynamic content part*/}
            <div
              className={`dynamic-content-container ${
                showDynamicContent5 ? "visible" : ""
              }`}>
              <div className='content-header py-4'>
                <div className='position-relative'>
                  <h4>Select the type of block you’d like to add:</h4>
                  <button
                    className='removeBoxBtn'
                    onClick={() => setShowDynamicContent5(false)}>
                    <i className='fa fa-times' aria-hidden='true'></i>
                  </button>
                </div>

                {/* Tab button area */}
                <ul
                  className='nav nav-pills mb-3 justify-content-center mt-3'
                  id='pills-tab5'
                  role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='pills-home-tab5'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-home4'
                      type='button'
                      role='tab'
                      aria-controls='pills-home'
                      aria-selected='true'>
                      CONTENT
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='pills-profile-tab5'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-profile5'
                      type='button'
                      role='tab'
                      aria-controls='pills-profile'
                      aria-selected='false'>
                      QUESTIONS
                    </button>
                  </li>
                </ul>
              </div>

              {/* Tab content area */}
              <div className='tab-content mt-4 pb-5 ' id='pills-tabContent1'>
                <div
                  className='tab-pane fade show active'
                  id='pills-home5'
                  role='tabpanel'
                  aria-labelledby='pills-home-tab'>
                  {/* Content Tab */}
                  <div className='row gy-4'>
                    {/* Full width box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box '>
                        <div className='item_box bg-white shadow-lg'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-12 skeleton-one'></div>
                            <div className='col-12 skeleton-two'></div>
                            <div className='col-12 skeleton-three'></div>
                            <div className='col-12 skeleton-four'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Full Width Text</h4>
                      </div>
                    </div>
                    {/*2 column text box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-text'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Text</h4>
                      </div>
                    </div>

                    {/*2 column Image box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Image</h4>
                      </div>
                    </div>
                    {/*2 cover Image box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box cover-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img src='/img/cover.png' alt='skeleton' />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Cover Image</h4>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Question Tab Content */}
                <div
                  className='tab-pane fade'
                  id='pills-profile5'
                  role='tabpanel'
                  aria-labelledby='pills-profile-tab'>
                  <div className='row gy-4'>
                    {/* Two fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box twoFields'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className=' skeleton-one'></div>
                            </div>
                            <div className='skeleton-box '>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Two fields</h4>
                      </div>
                    </div>
                    {/* Short text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box shortText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Short Text</h4>
                      </div>
                    </div>
                    {/* Long text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box longText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Long Text</h4>
                      </div>
                    </div>
                    {/* Dropdown fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/dropdown-e-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Dropdown</h4>
                      </div>
                    </div>
                    {/* single-choice-grey  fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/single-choice-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Single Choice</h4>
                      </div>
                    </div>
                    {/* multi-choice-blue */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-12 d-flex align-items-center'>
                              <img
                                src='/img/multi-choice-blue.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Multi choice blue</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* What type of event are you looking for? Field */}
        <div className='col-12'>
          <div className='mb-3 form_input'>
            <label htmlFor='name' className='form-label mt-0'>
              What type of event are you looking for?
            </label>
            <select
              style={{ height: "60px", borderRadius: "10px" }}
              className='form-select'
              aria-label='Default select example'>
              <option selected>select an option</option>
              <option value='1'>One</option>
              <option value='2'>Two</option>
              <option value='3'>Three</option>
            </select>
            {/* side_ellipse */}
            <div className='side_ellipse'>
              <div className='text-dark doubleEllipsis' href='#'>
                <i className='fas fa-ellipsis-v fa-xl  me-1'></i>
                <i className='fas fa-ellipsis-v fa-xl '></i>
              </div>
            </div>
            {/* add button */}
            <div className='plus_btn' onClick={handleToggleContent6}>
              <i className='fa fa-plus'></i>
            </div>

            {/* dynamic content part*/}
            <div
              className={`dynamic-content-container ${
                showDynamicContent6 ? "visible" : ""
              }`}>
              <div className='content-header py-4'>
                <div className='position-relative'>
                  <h4>Select the type of block you’d like to add:</h4>
                  <button
                    className='removeBoxBtn'
                    onClick={() => setShowDynamicContent6(false)}>
                    <i className='fa fa-times' aria-hidden='true'></i>
                  </button>
                </div>

                {/* Tab button area */}
                <ul
                  className='nav nav-pills mb-3 justify-content-center mt-3'
                  id='pills-tab6'
                  role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='pills-home-tab6'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-home6'
                      type='button'
                      role='tab'
                      aria-controls='pills-home'
                      aria-selected='true'>
                      CONTENT
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='pills-profile-tab6'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-profile6'
                      type='button'
                      role='tab'
                      aria-controls='pills-profile'
                      aria-selected='false'>
                      QUESTIONS
                    </button>
                  </li>
                </ul>
              </div>

              {/* Tab content area */}
              <div className='tab-content mt-4 pb-5 ' id='pills-tabContent1'>
                <div
                  className='tab-pane fade show active'
                  id='pills-home6'
                  role='tabpanel'
                  aria-labelledby='pills-home-tab'>
                  {/* Content Tab */}
                  <div className='row gy-4'>
                    {/* Full width box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box '>
                        <div className='item_box bg-white shadow-lg'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-12 skeleton-one'></div>
                            <div className='col-12 skeleton-two'></div>
                            <div className='col-12 skeleton-three'></div>
                            <div className='col-12 skeleton-four'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Full Width Text</h4>
                      </div>
                    </div>
                    {/*2 column text box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-text'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Text</h4>
                      </div>
                    </div>

                    {/*2 column Image box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Image</h4>
                      </div>
                    </div>
                    {/*2 cover Image box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box cover-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img src='/img/cover.png' alt='skeleton' />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Cover Image</h4>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Question Tab Content */}
                <div
                  className='tab-pane fade'
                  id='pills-profile6'
                  role='tabpanel'
                  aria-labelledby='pills-profile-tab'>
                  <div className='row gy-4'>
                    {/* Two fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box twoFields'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className=' skeleton-one'></div>
                            </div>
                            <div className='skeleton-box '>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Two fields</h4>
                      </div>
                    </div>
                    {/* Short text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box shortText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Short Text</h4>
                      </div>
                    </div>
                    {/* Long text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box longText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Long Text</h4>
                      </div>
                    </div>
                    {/* Dropdown fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/dropdown-e-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Dropdown</h4>
                      </div>
                    </div>
                    {/* single-choice-grey  fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/single-choice-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Single Choice</h4>
                      </div>
                    </div>
                    {/* multi-choice-blue */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-12 d-flex align-items-center'>
                              <img
                                src='/img/multi-choice-blue.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Multi choice blue</h4>
                      </div>
                    </div>
                    {/*Date Picker box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-text'>
                        <div className='item_box flex-wrap bg-white shadow-lg d-flex'>
                          <div
                            className='box_items '
                            style={{ columnGap: "5px" }}>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Date Picker</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  Field */}
        <div className='col-12'>
          <div className='mb-3 form_input'>
            <textarea
              rows='6'
              type='text'
              className='form-control'
              id='name'
              name='name'
              placeholder='What do I need to know about the project?'
            />
            {/* side_ellipse */}
            <div className='side_ellipse'>
              <div className='text-dark doubleEllipsis' href='#'>
                <i className='fas fa-ellipsis-v fa-xl  me-1'></i>
                <i className='fas fa-ellipsis-v fa-xl '></i>
              </div>
            </div>
            {/* add button */}
            <div className='plus_btn' onClick={handleToggleContent7}>
              <i className='fa fa-plus'></i>
            </div>

            {/* dynamic content part*/}
            <div
              className={`dynamic-content-container ${
                showDynamicContent7 ? "visible" : ""
              }`}>
              <div className='content-header py-4'>
                <div className='position-relative'>
                  <h4>Select the type of block you’d like to add:</h4>
                  <button
                    className='removeBoxBtn'
                    onClick={() => setShowDynamicContent7(false)}>
                    <i className='fa fa-times' aria-hidden='true'></i>
                  </button>
                </div>

                {/* Tab button area */}
                <ul
                  className='nav nav-pills mb-3 justify-content-center mt-3'
                  id='pills-tab6'
                  role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='pills-home-tab7'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-home7'
                      type='button'
                      role='tab'
                      aria-controls='pills-home'
                      aria-selected='true'>
                      CONTENT
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='pills-profile-tab7'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-profile7'
                      type='button'
                      role='tab'
                      aria-controls='pills-profile'
                      aria-selected='false'>
                      QUESTIONS
                    </button>
                  </li>
                </ul>
              </div>

              {/* Tab content area */}
              <div className='tab-content mt-4 pb-5 ' id='pills-tabContent1'>
                <div
                  className='tab-pane fade show active'
                  id='pills-home7'
                  role='tabpanel'
                  aria-labelledby='pills-home-tab'>
                  {/* Content Tab */}
                  <div className='row gy-4'>
                    {/* Full width box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box '>
                        <div className='item_box bg-white shadow-lg'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-12 skeleton-one'></div>
                            <div className='col-12 skeleton-two'></div>
                            <div className='col-12 skeleton-three'></div>
                            <div className='col-12 skeleton-four'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Full Width Text</h4>
                      </div>
                    </div>
                    {/*2 column text box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-text'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-6 skeleton-one'></div>
                            <div className='col-md-6 skeleton-two'></div>
                            <div className='col-md-6 skeleton-three'></div>
                            <div className='col-md-6 skeleton-three'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Text</h4>
                      </div>
                    </div>

                    {/*2 column Image box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                              <img
                                src='/img/image-skeleton.png'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>2 Column Image</h4>
                      </div>
                    </div>
                    {/*2 cover Image box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box cover-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-6 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img src='/img/cover.png' alt='skeleton' />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Cover Image</h4>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Question Tab Content */}
                <div
                  className='tab-pane fade'
                  id='pills-profile7'
                  role='tabpanel'
                  aria-labelledby='pills-profile-tab'>
                  <div className='row gy-4'>
                    {/* Two fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box twoFields'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className=' skeleton-one'></div>
                            </div>
                            <div className='skeleton-box '>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Two fields</h4>
                      </div>
                    </div>
                    {/* Short text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box shortText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Short Text</h4>
                      </div>
                    </div>
                    {/* Long text fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box longText'>
                        <div className='item_box bg-white d-flex align-items-center shadow-lg justify-content-center'>
                          <div className='row gap-2 d-flex align-items-center m-2'>
                            <div className='skeleton-box'>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                              <div className='col-12 skeleton-one'></div>
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Long Text</h4>
                      </div>
                    </div>
                    {/* Dropdown fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/dropdown-e-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Dropdown</h4>
                      </div>
                    </div>
                    {/* single-choice-grey  fields */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div
                              className='col-md-12 d-flex align-items-center'
                              style={{ columnGap: "5px" }}>
                              <img
                                src='/img/single-choice-grey.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Single Choice</h4>
                      </div>
                    </div>
                    {/* multi-choice-blue */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      <div className='content-box dropdown-image'>
                        <div className='item_box bg-white shadow-lg d-flex'>
                          <div className='row gap-2 align-items-start m-2'>
                            <div className='col-md-12 d-flex align-items-center'>
                              <img
                                src='/img/multi-choice-blue.svg'
                                alt='skeleton'
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Multi choice blue</h4>
                      </div>
                    </div>
                    {/*Date Picker box */}
                    <div className='col-6 col-md-4 col-lg-4'>
                      {" "}
                      <div className='content-box column-text'>
                        <div className='item_box flex-wrap bg-white shadow-lg d-flex'>
                          <div
                            className='box_items '
                            style={{ columnGap: "5px" }}>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                            <div className='box-skeleton'></div>
                          </div>
                        </div>
                        <h4 className='mt-2'>Date Picker</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
