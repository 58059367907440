import { useEffect, useState } from "react";
import { useEditableContent } from "../../Context/EditableContentContext";
import UnitOption from "../CreateInvoice/UnitOption";
import CommonDropdownMenu from "../Shared/CommonDropdownMenu";
import { Navigate } from "react-router-dom";

function AddProduct() {
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(true);

  useEffect(() => {
    const udata = localStorage.getItem('user');
    if (udata === null) {
      setLoggedIn(false);
    } else {
      const odata = JSON.parse(udata);
      setUser(odata.user);
    }
  }, []);
 
  const { editableProductContent, handleProductContentChange } =
    useEditableContent();
  const [isEditing, setIsEditing] = useState(false);
  const [newContent, setNewContent] = useState(editableProductContent);

  const handleInputChange = (e) => {
    setNewContent(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleProductContentChange(newContent);
      setIsEditing(false);
    }
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    handleProductContentChange(newContent);
    setIsEditing(false);
  };

  const options = ["Photo Booth", "Backdrop", "Props", "Additional Items"];
  const [qty, setQty] = useState("");
  const [price, setPrice] = useState(0);

  const handleQtyChange = (e) => {
    setQty(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };
  if (!loggedIn) {
    return <Navigate to="/" />;
  }
  // The return statement contains the JSX code which is rendered and displayed on the page
  return (
    //  ================= *****  TEMPLATES BODY JSX ***** =================
    <div className='container-fluid'>
      {/* ========== Product Item Window Container 01 ========== */}
      <div
        id='product-window'
        className='row g-lg-5 g-md-5 g-3 justify-content-center'>
        {/*========== Product Item Window ========== */}
        <div className='product-window-container col-sm-12 col-md-9 col-xl-9 bg-white rounded p-lg-5 p-md-3 p-2 shadow-sm'>
          {/*========== Item Title ========== */}
          <div className='product-window-title-container d-flex'>
            <div className='product-window-heading w-50'>
              <h5>Product Item</h5>
            </div>
            <div className='item-cell-container w-50 d-flex justify-content-around'>
              <div className='item-cell'>QTY</div>
              <div className='item-cell'>UNIT</div>
              <div className='item-cell'>TAX</div>
              <div className='item-cell'>PRICE</div>
            </div>
          </div>
          {/* put a line going all the way across */}
          <hr className='my-3' />
          {/*========== Product Body ========== */}
          <div
            className='product-body rounded p-lg-3 p-md-2 p-2 my-4'
            id='p-details'
            style={{ background: "#f5f5f5", border: "2px solid #e6e6e6" }}>
            {/* Item Body Text */}
            <div className='title-container d-flex mb-3 flex-column flex-md-row'>
              <div className='item-heading w-100'>
                <div onClick={handleClick}>
                  {isEditing ? (
                    <input
                      type='text'
                      className='border rounded outline-none'
                      value={newContent}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      onBlur={handleBlur}
                      autoFocus // Automatically focuses on the input field when it appears
                    />
                  ) : (
                    <h5>{editableProductContent}</h5>
                  )}
                </div>
                {/* <h5 contentEditable='true'>Product Item</h5> */}
              </div>
              <div className='item-cell-container w-100 w-md-50 d-flex justify-content-around mt-2'>
                <div className='item-cell'>
                  {" "}
                  <input
                    type='number'
                    value={qty}
                    onChange={handleQtyChange}
                    className='input-qyt'
                    placeholder='QTY'
                  />
                </div>
                <div className='item-cell position-relative'>
                  <UnitOption />
                </div>
                <div className='item-cell'>
                  <input
                    className='form-check-input mt-0'
                    type='checkbox'
                    defaultValue=''
                    aria-label='Checkbox for following text input'
                  />
                </div>
                <div className='item-cell'>
                  <input
                    type='number'
                    value={price}
                    onChange={handlePriceChange}
                    className='input-qyt text-end'
                  />
                </div>
              </div>
            </div>
            <input
              type='text'
              placeholder='Product Description'
              className='border-0 fs-5 text-black bg-transparent'
              style={{
                outline: "none",
              }}
            />
          </div>
          {/* Additional Charge Per Hour */}
          {/* <div
            className='rounded p-lg-3 p-md-2 p-2 my-4'
            style={{ background: "#f5f5f5", border: "2px solid #e6e6e6" }}>
            <table className='table table-borderless mb-0'>
              <tbody>
                <tr>
                  <td className='w-75'>
                    <h5 className='m-0 fw-normal'>
                      Additional Charge Per Hour
                    </h5>
                  </td>
                  <td className='w-10 text-end'>$0</td>
                </tr>
              </tbody>
            </table>
          </div> */}
          {/* ======= ADD CATEGORY DROPDOWN MENU BUTTON COMPONENT ======= */}
          <div className='col-md-6'>
            <label className='mb-2'>Product Category</label>
            <CommonDropdownMenu options={options} />
          </div>
          {/* ======= ADD CATEGORY DROPDOWN MENU BUTTON ======= */}
          {/* <div id="add-category-btn" className="accordion">
            <p className="overflow-hidden m-0" id="p-categ">
              Product Category
            </p>
            <div className="accordion-header">
              <span className="selected-option"> </span>
              <span className="icon overflow-hidden">
                <i className="fa-solid fa-caret-down" />
              </span>
            </div>
            <div
              className="accordion-content overflow-hidden"
              id="accordion-content"
            >
              <div className="option">Photo Booth</div>
              <div className="option">Backdrop</div>
              <div className="option">Props</div>
              <div className="option">Additional items</div>
              <div className="input-with-button" id="input">
                <input type="text" placeholder="New Category..." />
                <button id="createButton">CREATE</button>
              </div>
              <button id="button">+ Add Category</button>
            </div>
          </div> */}
          {/* ========== UPLOAD IMAGES ========== */}
          <div className='row g-lg-3 g-sm-3 g-2 justify-content-center mt-4'>
            <div className='col-md-3 col-sm-4 col-4'>
              <div className='upload-btn-wrapper p-0'>
                <img src='img/file-upload.png' className='img-fluid' alt='' />
                <input className='input-upload-btn' type='file' name='myfile' />
              </div>
            </div>
            <div className='col-md-3 col-sm-4 col-4'>
              <div className='upload-btn-wrapper p-0'>
                <img src='img/file-upload.png' className='img-fluid' alt='' />
                <input className='input-upload-btn' type='file' name='myfile' />
              </div>
            </div>
            <div className='col-md-3 col-sm-4 col-4'>
              <div className='upload-btn-wrapper p-0'>
                <img src='img/file-upload.png' className='img-fluid' alt='' />
                <input className='input-upload-btn' type='file' name='myfile' />
              </div>
            </div>
          </div>
          {/* ========== UPLOAD IMAGES ========== */}
        </div>
        {/* ========== DONE BUTTON ========== */}
        <div className='text-center pt-2'>
          <button
            id='submit-btn'
            type='button'
            className='px-5 rounded-pill btn btn-primary btn-lg text-uppercase'>
            Done
          </button>
        </div>
      </div>
      {/* ========== Product Item Window Container 01 - END ========== */}
    </div>
  );
}

export { AddProduct };
