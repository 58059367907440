import React from "react";

export default function TabNav() {
  return (
    <>
      <ul className='nav flex-column company-tab'>
        <li className='nav-item'>
          <a
            className='nav-link text-muted active'
            id='tab1'
            data-bs-toggle='pill'
            href='#content7'>
            COMPANY BRAND{" "}
          </a>
        </li>
        <li className='nav-item'>
          <a
            className='nav-link text-muted'
            id='tab2'
            data-bs-toggle='pill'
            href='#content8'>
            PREFERENCES{" "}
          </a>
        </li>

        <li className='nav-item'>
          <a
            className='nav-link text-muted'
            id='tab3'
            data-bs-toggle='pill'
            href='#content3'>
            PAYMENT METHODS
          </a>
        </li>
        <li className='nav-item'>
          <a
            className='nav-link text-muted'
            id='tab3'
            data-bs-toggle='pill'
            href='#content4'>
            TEAM{" "}
          </a>
        </li>
      </ul>
    </>
  );
}
