import React, { useState } from "react";
import CommonDropdownMenu from "../../../Shared/CommonDropdownMenu";
import CreateNewClientForm from "../../AddCustomerInfoModal/CreateNewClientForm";
import ExistingClient from "../../AddCustomerInfoModal/ExistingClient";
function NewEventModal() {
  const [activeTab, setActiveTab] = useState("createNewClient");
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);

  const [isTabChanging, setIsTabChanging] = useState(false);

  const handleTabChange = (tabId) => {
    setIsTabChanging(true);
    setActiveTab(tabId);
    setTimeout(() => {
      setIsTabChanging(false);
    }, 100); // Adjust the duration according to your transition time
  };

  const handleCreateEventClick = () => {
    setShowUserInfoModal(true);
  };
  const options = ["Wedding", "Sweet 16", "Birthday", "Party"];
  const optionsLeadSource = [
    "Personal Website",
    "Google",
    "Facebook",
    "Instagram",
  ];

  const onClose = () => {
    setShowUserInfoModal(false);
  };
  return (
    <div
      className='modal fade m-0 p-0'
      id='staticBackdrop'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'>
      <div className='modal-dialog newEventsModal_dialog modal-dialog-centered'>
        {/* New Event Modal */}
        <div
          className={`modal-content event_modal ${
            showUserInfoModal ? "d-none" : ""
          }`}>
          {/* Modal header */}
          <div className='modal-header' style={{ backgroundColor: "#4f46e5" }}>
            <h5
              className='modal-title text-center w-100'
              id='exampleModalLabel'
              style={{ color: "#fff", fontSize: "22px" }}>
              New Event
            </h5>
            <button type='button' className='close' data-bs-dismiss='modal'>
              <span aria-hidden='true' className='modal_button'>
                <i className='fas fa-close'></i>
              </span>
              <span className='sr-only'>Close</span>
            </button>
          </div>
          <div className='modal-body px-5  event_modal_cont'>
            <form>
              <div className='row'>
                <div className='col-md-12'>
                  <label htmlFor='eventName'>Event Name</label>
                  <input
                    name='eventName'
                    id='eventName'
                    type='text'
                    className='form-control'
                    placeholder='Event Name'
                  />
                </div>
                <div className='col-md-12 mt-4'>
                  <label htmlFor='eventAddress'>Event Address</label>
                  <input
                    name='eventAddress'
                    id='eventAddress'
                    type='text'
                    className='form-control'
                    placeholder='Event Address'
                  />
                </div>
                <div className='col-md-6 mt-4'>
                  <label>Event Type</label>
                  <CommonDropdownMenu options={options} />
                </div>
                <div className='col-md-6 mt-4'>
                  <label>Lead Source</label>
                  <CommonDropdownMenu options={optionsLeadSource} />
                </div>

                <div className='col-md-8 mt-4 '>
                  <label htmlFor='inputCustomer' className='form-label fs-dark'>
                    {" "}
                    Event Date
                  </label>
                  <div className='row g-3'>
                    <div className='col-md-4'>
                      <input
                        style={{ fontSize: "14px" }}
                        type='date'
                        className='form-control'
                        placeholder='Event Date'
                        aria-label='Event Date'
                      />
                    </div>
                    <div className='col-md-8'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <input
                            style={{ fontSize: "14px" }}
                            type='time'
                            className='form-control'
                            placeholder='Start Time'
                            aria-label='Start Time'
                          />
                        </div>
                        <div className='col-md-6 mt-3 mt-md-0'>
                          <input
                            style={{ fontSize: "14px" }}
                            type='time'
                            className='form-control'
                            placeholder='End Time'
                            aria-label='End Time'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-4 mt-5 p-4 text-end'>
                  <button
                    type='button'
                    className='but-primary'
                    onClick={handleCreateEventClick}>
                    Create Event
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* User info modal */}
        {showUserInfoModal && (
          <div className='modal-content userInfoModal-content'>
            {/* Modal header */}
            <div
              className='modal-header'
              style={{ backgroundColor: "#4f46e5" }}>
              <h5
                className='modal-title text-center w-100'
                id='exampleModalLabel'
                style={{ color: "#fff", fontSize: "22px" }}>
                Customer Info
              </h5>
              <button
                type='button'
                className='close'
                onClick={onClose}
                data-bs-dismiss='modal'>
                <span aria-hidden='true' className='modal_button'>
                  <i className='fas fa-close'></i>
                </span>
                <span className='sr-only'>Close</span>
              </button>
            </div>
            <div className='modal-body px-3 px-md-5 modal_tabs event_modal_cont'>
              <div className=''>
                <div className='d-flex align-items-center tabs_btn'>
                  <div>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='createNewClient1'
                      name='tab'
                      onClick={() =>
                        !isTabChanging && handleTabChange("createNewClient")
                      }
                      checked={activeTab === "createNewClient"}
                      disabled={isTabChanging}
                    />
                    <label htmlFor='createNewClient1' className='ms-1'>
                      Create New Client
                    </label>
                  </div>
                  <div className='ms-2 ms-md-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='existingClient1'
                      name='tab'
                      onClick={() =>
                        !isTabChanging && handleTabChange("existingClient")
                      }
                      checked={activeTab === "existingClient"}
                      disabled={isTabChanging}
                    />
                    <label htmlFor='existingClient1' className='ms-1'>
                      Existing Client
                    </label>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-12'>
                    <div
                      id='v-pills-home'
                      className={`tab-pane ${
                        activeTab === "createNewClient"
                          ? "show active"
                          : "fade d-none"
                      }`}>
                      <CreateNewClientForm />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div
                      id='v-pills-profile'
                      className={`tab-pane ${
                        activeTab === "existingClient"
                          ? "show active"
                          : "fade d-none"
                      }`}>
                      <ExistingClient />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewEventModal;
