// Importing the createBrowserRouter function from react-router-dom library to handle routing in our React application
import { createBrowserRouter } from "react-router-dom";
// Important Components so i can connect the Template Button to the Template Page

// ============== ******* Importing the Components that will be used as pages for the different routes ******* ==============

// When you import a component and it does not have the {} around it, it means that it is the default export of the file.

// Importing the components that will be used as pages for the different routes

import DashboardLayout from "../Layout/DashboardLayout";
import { AddProduct } from "../components/Add-Product/Add-product";
import { DayCalendar } from "../components/Calenders/DayCalendar";
import { MonthAvailability } from "../components/Calenders/MonthAvaibility";
import MonthCalendar from "../components/Calenders/MonthCalendar";
import CompanySetting from "../components/Company-Setting/CompanySetting";
import CompanyTab from "../components/Company-Setting/Tab-Content/Compnay/CompanyTab";
import MyAccountTab from "../components/Company-Setting/Tab-Content/My-Account/MyAccountTab";
import ContractForm from "../components/ContractForm/ContractForm";
import { ContractFormMain } from "../components/ContractForm/ContractFormMain";
import Contracts from "../components/Contracts/Contracts";
import CreateContract from "../components/Create-Contract/CreateContract";
import CreateInvoice from "../components/CreateInvoice/CreateInvoice";
import DashboardHome from "../components/DashboardHome/DashboardHome";
import AllEvents from "../components/Events/All-Events/AllEvents";
import CustomerEvent from "../components/Events/Customer-Event/CustomerEvent";
import Invoices from "../components/Invoices/Invoices";
import { CreateNewPackage } from "../components/Package/CreateNewPackage";
import { Package } from "../components/Package/Package";
import { Products } from "../components/Products/Products";
import CreateProposal from "../components/Proposals/CreateProposal";
import { Proposals } from "../components/Proposals/Proposals";
import { Templates } from "../components/Templates/Templates";
import Login from "../components/auth/login";
import SignUp from "../components/auth/signu";
import Logout from "../components/auth/logout";

// ============== ******* Importing the Components that will be used as pages for the different routes ******* ==============

// React Router Funciton used for Creating Routes / Links that are used to navigate between pages
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/logout",
      element: <Logout />,
    },
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      /* ====================== Start: Dashboard home route ====================== */
      
      {
        path: "/dashboard",
        element: <DashboardHome />,
      },
      /* ====================== End: Dashboard home route ====================== */

      /* ====================== Start: Template route ====================== */
      {
        path: "templates",
        element: <Templates />,
      },
      /* ====================== End: Template route ====================== */

      /* ====================== Start: Product routes ====================== */
      {
        path: "products",
        element: <Products />,
      },

      {
        path: "add-product",
        element: <AddProduct />,
      },
      /* ====================== End: Product routes ====================== */

      /* ====================== Start: Package routes ====================== */

      {
        path: "package",
        element: <Package />,
      },
      {
        path: "create-package",
        element: <CreateNewPackage />,
      },
      /* ====================== End: Package routes ====================== */

      /* ====================== Start: Contract routes ====================== */
      {
        path: "contracts",
        element: <Contracts />,
      },

      {
        path: "create-contract",
        element: <CreateContract />,
      },
      /* ====================== End: Contract routes ====================== */
      /* ====================== start: Contract form ====================== */
      {
        path: "contract-form",
        element: <ContractFormMain />,
      },
      {
        path: "new-contract",
        element: <ContractForm />,
      },
      /* ====================== end: Contract form ====================== */
      /* ====================== Start: Contract routes ====================== */
      {
        path: "invoices",
        element: <Invoices />,
      },
      {
        path: "create-invoice",
        element: <CreateInvoice />,
      },
      /* ====================== End: Contract routes ====================== */

      /* ====================== Start: Proposals routes ====================== */
      {
        path: "proposals",
        element: <Proposals />,
      },
      {
        path: "create-proposals",
        element: <CreateProposal />,
      },
      /* ====================== End: Proposals routes ====================== */

      /* ====================== Start: Calendar routes ====================== */
      {
        path: "month-availability",
        element: <MonthAvailability />,
      },
      {
        path: "day-calendar",
        element: <DayCalendar />,
      },
      {
        path: "month-calendar",
        element: <MonthCalendar />,
      },
    
      /* ====================== End: Calendar routes ====================== */

      /* ====================== Start: Company Setting routes ====================== */
      {
        path: "company-setting",
        element: <CompanySetting />,
        children: [
          {
            path: "my-account",
            element: <MyAccountTab />,
          },
          {
            path: "my-company",
            element: <CompanyTab />,
          },
        ],
      },
      /* ====================== End: Company Setting routes ====================== */

      /* ====================== Start: Events routes ====================== */
      {
        path: "events",
        element: <AllEvents />,
      },
      {
        path: "customer-events",
        element: <CustomerEvent />,
      },
      /* ====================== End: Events routes ====================== */
    ],
  },
]);

/* ============================= This whole page is dedicatedly created for routing. */
