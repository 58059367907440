/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../../../../apis/apiManaget";
import { ToastContainer, toast } from "react-toastify";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
export default function BrandElement() {
  const [isChecked, setIsChecked] = useState(false);
  // const [value, setvalue] = useState(true);

  const [formData, setFormData] = useState({
    main_logo: "",
    secondary_logo: "",
    default_header_image: "",
  });

  const [formData2, setFormData2] = useState({
    main_logo: "",
    secondary_logo: "",
    default_header_image: "",
  });
  console.log("formdata", formData2);
  useEffect(() => {
    const check = Object.keys(formData);
    check.forEach((value) => {
      console.log(formData[value]);
      if (formData[value] !== "") {
        update(formData); // Call your update function after a delay
      }
    });
  }, [
    formData.main_logo,
    formData.secondary_logo,
    formData.default_header_image,
    formData.is_default_header_info,
  ]);

  useEffect(() => {
    const setingdata = async () => {
      try {
        const token = await localStorage.getItem("access_token");
        const getsetibg = await axios.get(API.COMPANY.GET_COMPANY_TEMPLATES, {
          headers: {
            Authorization: `bearer ${token}`,
            "Content-Type": `multipart/form-data; `,
          },
        });
        const setting = getsetibg?.data?.setting;
        console.log("get data of seting", getsetibg?.data?.setting);
        setFormData2({
          main_logo: setting?.main_logo,
          secondary_logo: setting?.secondary_logo,
          default_header_image: setting?.default_header_image,
          is_default_header_info: setting?.is_default_header_info,
        });
        setIsChecked(setting?.is_default_header_info === 1 ? true : false);
      } catch (error) {
        console.log("error", error);
      }
    };
    setingdata();
  }, []);
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "file") {
      const reader = new FileReader();
      const file = e.target.files[0];

      reader.onloadend = () => {
        setFormData({
          ...formData,
          [name]: file,
        });
        setFormData2({
          ...formData2,
          [name]: reader.result,
        });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      setFormData2({
        ...formData2,
        [name]: value,
      });
    }
  };

  const toggleHeaderInfo = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked); // Update the checked state immediately
    console.log("checked true", checked);
    const newData = {
      is_default_header_info: checked ? 1 : 0, // Set value based on checked state
    };

    // Update the formData state after isChecked state is updated
    setFormData(newData);

    setTimeout(() => {
      console.log("");
      update(newData); // Call your update function after a delay
    }, 2000);
  };

  const update = async (a) => {
    try {
      const token = await localStorage.getItem("access_token");
      console.log("===========================>", a);
      const response = await axios.post(
        API.COMPANY.COMPANY_TEMPLATES,
        a,

        {
          headers: {
            Authorization: `bearer ${token}`,
            "Content-Type": `multipart/form-data; `,
          },
        }
      );
      if (response.data) {
        console.log("========sss", response.data);
        toast.success(response.data.message);
        setFormData({
          main_logo: "",
          secondary_logo: "",
          default_header_image: "",
        });
      }
      console.log("getScreeningData", response.data); // Log the data received from the backend
    } catch (error) {
      console.log(error); // Log any errors that occur during the request
    }
  };

  return (
    <div className="mb-3 row col-md-12 bg-white m-auto product-body rounded p-lg-3 p-md-2 p-2 my-4">
      <p className="fw-bolder fs-4">Brand Elements </p>

      <div className="mb-3 col-md-6 pl-5 border-left ">
        {/* Main Logo */}
        <div className="mb-3 col-md-12 d-flex flex-column flex-md-row">
          <div className="col-md-4 me-5">
            <p className="card-title">Main Logo</p>
            <label htmlFor="mainLogo" className="MainLogo">
              {formData2.main_logo && (
                <img
                  src={formData2.main_logo}
                  alt="Main Logo Preview"
                  className="mainimgpath img-fluid h-100"
                />
              )}
              {!formData2.main_logo && (
                <img
                  src="/img/photo-gallery.png"
                  alt="Main Logo Placeholder"
                  className="mainimgpath img-fluid h-100"
                />
              )}
              <span className="text-content d-block">100 x 100</span>
              <input
                type="file"
                id="mainLogo"
                name="main_logo"
                className="d-none"
                onChange={handleChange}
              />
            </label>
          </div>

          {/* Repeat similar pattern for other image inputs */}
          {/* Secondary Logo */}
          <div className="col-md-6">
            <p className="card-title">Secondary Logo</p>
            <label
              htmlFor="secondaryLogo"
              className="SecondaryLogo justify-content-center align-items-center d-flex flex-row"
            >
              {formData2.secondary_logo && (
                <img
                  src={formData2.secondary_logo}
                  alt="Secondary Logo Preview"
                  className="SecondaryLogopath img-fluid"
                />
              )}
              {!formData2.secondary_logo && (
                <img
                  src="/img/photo-gallery.png"
                  alt="Secondary Logo Placeholder"
                  className="SecondaryLogopath img-fluid"
                />
              )}
              <span className="text-content">300 x 100 px</span>

              <input
                type="file"
                id="secondaryLogo"
                name="secondary_logo"
                className="d-none"
                onChange={handleChange}
              />
              
            </label>
          </div>
        </div>

        {/* Default Header Images */}
        <div className="col-md-12">
          <p className="card-title">Default Header Images</p>
          <label
            htmlFor="defaultHeaderImage"
            className="DefaultHeaderlogo justify-content-center "
          >
            {formData2.default_header_image && (
              <img
                src={formData2.default_header_image}
                alt="Default Header Image Preview"
                className="DefaultHeaderlogoImg"
              />
            )}
            {!formData2.default_header_image && (
              <img
                src="/img/photo-gallery.png"
                alt="Default Header Image Placeholder"
                className="DefaultHeaderlogoImg"
              />
            )}
            <span className="text-content d-block">750x300 px</span>
            <input
              type="file"
              id="defaultHeaderImage"
              name="default_header_image"
              className="d-none"
              onChange={handleChange}
            />
          </label>
        </div>

        {/* Default Header Info */}
        <div className="card border-0 mt-4">
          <p className="fs-10 m-0 py-2">Default Header Info</p>
          <p>
            <div className="form-check form-switch d-flex align-items-center">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckChecked"
                name="is_default_header_info"
                checked={
                  isChecked ? isChecked : formData2?.is_default_header_info
                }
                // value={}
                onChange={(e) => {
                  toggleHeaderInfo(e);
                }}
              />
              <label
                className="form-check-label mt-1"
                htmlFor="flexSwitchCheckChecked"
              >
                include Info in Default Header
              </label>
            </div>
          </p>
        </div>
      </div>
      {/* <button onClick={update}>submit</button> */}
      <div className="mb-3 col-md-6">
        <p className="fs-5" style={{ lineHeight: "7px" }}>
          Header Preview
        </p>

        <div className="mb-3 h-30  headermainsection">
          <div className="HeaderPreview justify-content-center">
            <div className="center-cirlce-header">
              <div className="CUSTOMERLOGO">CUSTOMER LOGO</div>
            </div>
            <span className="leftsidecut"></span>
            <span className="headerinner">HEADER</span>
            <span className="innserimage">IMAGE</span>
            <span className="rightsidecut"></span>
            <p className="p-fs-10">Company Photo Booth Name</p>
            <p className="p-fs-15">
              Ruben Esquivel | 214-555-5555 | name@companyinfo.com
            </p>
          </div>

          <div className="section-content my-3">
            <div className="section-content1 mx-3 my-4">
              <p className="section1"></p>
              <p className="section2"></p>
              <p className="section3"></p>
              <p className="section2"></p>
            </div>

            <div className="section-content1  mx-3 my-4">
              <p className="section1"></p>
              <p className="section2"></p>
              <p className="section3"></p>
              <p className="section2"></p>
            </div>

            <div className="section-content1  mx-3 my-4">
              <p className="section1"></p>
              <p className="section2"></p>
              <p className="section3"></p>
              <p className="section2"></p>
            </div>
          </div>
        </div>

        <div className="mb-3 ">
          <p className="fs-12 m-0 fw-bold">Brand color:</p>
          <p className="fs-18 m-0">
            Your brand color will be used in button in email, your contact form,
            your client portal and much more.{" "}
          </p>

          <div className="m-2 border-custom-colour d-flex align-items-center">
            <p className="cricledot m-0 pe-3"></p>
            <p className="colourcode m-0">
              {" "}
              #000000 <i className="fa-solid fa-pencil"></i>{" "}
            </p>
            {/* <input type="text" value="#000000" className="w-20 left border-0  circular-input" /> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
