import React from "react";
import "./ContractForm.css";

export default function ContractFormModal() {
  return (
    <div
      className='modal fade modal_contract_form'
      id='staticBackdrop01'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header px-2 px-md-5 py-4'>
            <div className='modal-header-left-area d-flex align-items-center'>
              <div className='checkIcon'>
                <i className='fa-solid fa-check'></i>
              </div>
              <p>Your Contract Form has been successfully created</p>
            </div>
            <button type='button' className='close' data-bs-dismiss='modal'>
              <span aria-hidden='true' className='modal_button'>
                <i className='fas fa-close'></i>
              </span>
              <span className='sr-only'>Close</span>
            </button>
          </div>
          <div className='modal-body'>
            <div className='modal-body-warper text-md-center text-start'>
              <div className='top-part'>
                <span>
                  Copy the code below and paste it anywhere on your site where
                  you have access to the source code
                </span>
                <div className='input-addons py-4'>
                  <input
                    type='text'
                    placeholder='<div class="hb-p-63ec264b7678da0007564f6b-6"></div>'
                  />
                  <button>Copy Code</button>
                </div>

                <span>
                  Your Contract Form is live and ready to gather leads! If you
                  prefer not to embed it, simply share this direct link to start
                  getting bookings faster
                </span>
              </div>

              <div className='bottom-part d-flex flex-column py-5'>
                <label className='text-start ms-md-5 mb-1'>
                  <strong>Get Direct Link</strong>
                  <p className='m-0'>No embedding necessary</p>
                </label>
                <div className='input-addons'>
                  <input
                    type='text'
                    placeholder='www.photoboothadminking.com/widget/name..'
                  />
                  <button>Copy Code</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
