import React from "react";

export default function ExistingClient() {
  const data = [
    {
      avatar: "",
      name: "Emily Johnson",
      email: "emily.johnson@gmail.com",
    },
    {
      avatar: "",
      name: "Carlos Rodriguez",
      email: "carlos.rodriguez@gmail.com",
    },
    {
      avatar: "",
      name: "Priya Singh",
      email: "priya.singh@gmail.com",
    },
  ];
  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <form className='search-input'>
            <i className='fa fa-search' aria-hidden='true'></i>
            <input
              type='text'
              className='form-control'
              placeholder='Type to search client'
            />
          </form>
        </div>
        <div className='col-12 mt-4'>
          <div className='search-result'>
            {data.map((userInfo, i) => {
              return (
                <div className='single_user d-flex align-items-center' key={i}>
                  <div className='avatar'>
                    {userInfo.avatar ? (
                      <img src='' alt='Avatar' />
                    ) : (
                      <span>{userInfo.name.substring(0, 2)}</span>
                    )}
                  </div>
                  <div className='info'>
                    <h6>{userInfo.name}</h6>
                    <span>{userInfo.email}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className='col-md-12 mt-5 p-4 text-end'>
          <button type='button' className='but-primary' data-bs-dismiss='modal'>
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
